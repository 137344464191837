import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import AppContext from "../../../helpers/AppContext";
import {
	ControlRow,
	FlexHeader,
	MainCard,
	ThreeColField,
	TitleH1,
	TitleH1NoBase,
	TwoColField,
} from "../../../helpers/BaseLayoutStyles";
import Loading from "../../../components/Loading";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Checkbox } from "baseui/checkbox";
import { Button, SHAPE, SIZE } from "baseui/button";
import { Tag } from "baseui/tag";
import RoleModal from "./RoleModal";
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal";
import Actions from "../../../components/Actions";
import Breadcrumb from "../../../components/Breadcrumb";
import { Tab, Tabs } from "baseui/tabs-motion";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import NavButton from "../../../components/grid/NavButton";
import DeleteButton from "../../../components/grid/DeleteButton";

const Page = () => {
	const urlParams = useParams() as any;
	const appContext = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [actionPending, setActionPending] = useState(false);
	const [pageData, setPageData] = useState([] as any);
	const [pageMode, setPageMode] = useState("CREATE");
	const [rolesList, setRolesList] = useState([] as any);
	const [activeKey, setActiveKey] = React.useState("0");

	// State vars for handling the temp password display and redirect
	const [showTempPassword, setShowTempPassword] = useState(false);
	const [userCreatedData, setUserCreatedData] = useState([] as any);

	// Grid Configuration
	const [roleGridApi, setRoleGridApi] = useState(null) as any;
	const [roleGridColumnApi, setRoleGridColumnApi] = useState(null);
	const onRoleGridReady = (params: any) => {
		setRoleGridApi(params.api);
		setRoleGridColumnApi(params.columnApi);
	};

	const [organisationGridApi, setOrganisationGridApi] = useState(null) as any;
	const [organisationGridColumnApi, setOrganisationGridColumnApi] = useState(null);
	const onOrganisationGridReady = (params: any) => {
		setOrganisationGridApi(params.api);
		setOrganisationGridColumnApi(params.columnApi);
	};

	const [pageGridApi, setPageGridApi] = useState(null) as any;
	const [pageGridColumnApi, setPageGridColumnApi] = useState(null);
	const onPageGridReady = (params: any) => {
		setPageGridApi(params.api);
		setPageGridColumnApi(params.columnApi);
	};

	let navigate = useNavigate();

	const setPageDataProperty = async (key: string, value: any, remove: boolean = false) => {
		let staleData = Object.assign({}, pageData);

		if (Array.isArray(staleData[key])) {
			// Push and Pop items from the array
			if (!remove) {
				staleData[key].push(value);
			} else {
				// This assumes that both the value provided and the objects in the array have id fields
				// Find the object matching our id in the array.
				let re = staleData[key].find((e: any) => e.id == value.id);
				if (re != null) {
					// Object found, now remove it.
					const index = staleData[key].indexOf(re, 0);
					if (index > -1) {
						staleData[key].splice(index, 1);
					}
				}
			}
		} else {
			// Just update the value
			staleData[key] = value;
		}
		setPageData(staleData);
	};

	const getPageData = async () => {
		setLoading(true);

		// Ensure we don't pass through userId if it's undefined or null
		let response = await appContext.http.request(
			"GET",
			`/api/admin/user/detail/` + (urlParams.userId != null ? urlParams.userId : ""),
			null,
			true,
			true
		);

		setLoading(false);
		if (response.success && response.data && response.data.success) {
			if (pageMode == "CREATE" && urlParams.organisationId && urlParams.organisationId != "") {
				response.data.userOrganisations.push({ id: urlParams.organisationId });
			}
			setPageData(response.data);
		}
	};

	const getRolesList = async () => {
		let response = await appContext.http.request("GET", `/api/admin/user/rolelist`, null, true, true);
		if (response.success && response.data && response.data.success) {
			setRolesList(response.data.items);
		}
	};

	const saveUser = async () => {
		// Validation logic

		// Build and post the request
		setActionPending(true);

		let result = await appContext.http.request("POST", "/api/admin/user/save", pageData, true, true);
		setActionPending(false);
		if (result.success) {
			toast.success("Changes saved successfully.");
			if (pageMode == "CREATE") {
				setUserCreatedData(result.data);
				if (result.data.showTempPassword == true) {
					// If creating we need to show the temp password popup. Then after closing we'll redirect to editing that user.
					setShowTempPassword(true);
				} else {
					redirectToEdit();
				}
			}
		}
	};

	const redirectToEdit = () => {
		setShowTempPassword(false);
		navigate(`/admin/users/edit/${userCreatedData.id}`);
	};

	useEffect(() => {
		if (urlParams.userId && urlParams.userId != "") {
			setPageMode("EDIT");
		}
		getPageData();
		getRolesList();
	}, [appContext, urlParams.userId]);

	const [roleModalOpen, setRoleModalOpen] = useState(false);

	return (
		<>
			<MainCard>
				{loading == false && pageData && (
					<FlexHeader>
						<Breadcrumb breadcrumbs={pageData.breadcrumbs} />
						{urlParams.userId ? (
							<>
								<TitleH1>{pageData.name}</TitleH1>
							</>
						) : (
							<TitleH1>Create User</TitleH1>
						)}
						<div></div>
					</FlexHeader>
				)}
				<div>
					{loading == true && <Loading key="loading" />}
					{loading == false && pageData != null && (
						<div>
							<Tabs
								activeKey={activeKey}
								onChange={({ activeKey }) => {
									setActiveKey(activeKey.toString());
								}}
								activateOnFocus
							>
								<Tab title="User Details">
									<TwoColField>
										<div>
											<FormControl label={() => "Full Name"}>
												<Input
													value={pageData.name}
													onChange={(e) => {
														setPageDataProperty("name", e.currentTarget.value);
													}}
												/>
											</FormControl>

											<FormControl label={() => "Email"}>
												<Input
													value={pageData.email}
													onChange={(e) => {
														setPageDataProperty("email", e.currentTarget.value);
													}}
												/>
											</FormControl>
											<TwoColField>
												<div>
													<FormControl label={() => "Active"}>
														<Checkbox
															checked={pageData.active}
															onChange={() => {
																setPageDataProperty("active", !pageData.active);
															}}
														/>
													</FormControl>
												</div>
												<div>
													{pageData && pageData.userOrganisations && pageData.userOrganisations.length == 0 && (
														<FormControl label={() => "System User"}>
															<Checkbox
																checked={pageData.systemUser}
																onChange={() => {
																	setPageDataProperty("systemUser", !pageData.systemUser);
																}}
															/>
														</FormControl>
													)}
												</div>
											</TwoColField>
										</div>
										<div>
											<FormControl label={() => "Roles"}>
												<div className="ag-theme-alpine" style={{ height: 300, width: "100%" }}>
													<AgGridReact
														rowData={pageData.userRoles}
														frameworkComponents={{
															navButtonRenderer: NavButton,
															deleteButtonRenderer: DeleteButton,
														}}
														defaultColDef={{
															flex: 1,
															minWidth: 100,
															editable: true,
															resizable: true,
															sortable: true,
															filter: true,
														}}
														onGridReady={onRoleGridReady}
													>
														<AgGridColumn field="name" />
														<AgGridColumn field="authScopeName" headerName="Scope" />

														<AgGridColumn
															field="id"
															editable={false}
															cellRenderer="deleteButtonRenderer"
															headerName=""
														/>
													</AgGridReact>
													<Actions
														saveAction={saveUser}
														saveActionPending={actionPending}
														addAction={() => setRoleModalOpen(true)}
														addTitle="Role"
													></Actions>
													<RoleModal
														isOpen={roleModalOpen}
														setIsOpen={setRoleModalOpen}
														title="Add Role"
														options={rolesList}
														currentValues={pageData.userRoles}
														authScopeId={pageData.authScopeId}
														authScopeName={pageData.authScopeName}
														onAdd={(value: any) => {
															setPageDataProperty("userRoles", value, false);
															roleGridApi.setRowData(pageData.userRoles);
														}}
													/>
												</div>
											</FormControl>
										</div>
									</TwoColField>
								</Tab>

								{pageData.authScopeId == null && pageData.userOrganisations && (
									<Tab
										title={
											"Organisations" + (pageData.userOrganisations && ` ( ${pageData.userOrganisations.length} ) `)
										}
										disabled={pageMode == "CREATE"}
									>
										<div className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
											<AgGridReact
												rowData={pageData.userOrganisations}
												defaultColDef={{
													flex: 1,
													minWidth: 100,
													editable: false,
													resizable: true,
													sortable: true,
													filter: true,
												}}
												pagination={true}
												paginationPageSize={10}
												onGridReady={onOrganisationGridReady}
											>
												<AgGridColumn field="name" />
											</AgGridReact>
										</div>
									</Tab>
								)}

								{pageData.authScopeId == null && pageData.userPages && (
									<Tab
										title={"Pages" + (pageData.userPages && ` ( ${pageData.userPages.length} ) `)}
										disabled={pageMode == "CREATE"}
									>
										<div className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
											<AgGridReact
												rowData={pageData.userPages}
												defaultColDef={{
													flex: 1,
													minWidth: 100,
													editable: false,
													resizable: true,
													sortable: true,
													filter: true,
												}}
												pagination={true}
												paginationPageSize={10}
												onGridReady={onPageGridReady}
											>
												<AgGridColumn field="name" />
											</AgGridReact>
										</div>
									</Tab>
								)}
							</Tabs>
						</div>
					)}
				</div>
			</MainCard>

			<Modal onClose={redirectToEdit} isOpen={showTempPassword}>
				<ModalHeader>Temporary Password</ModalHeader>
				<ModalBody>
					A temporary password has been set. This will last for seven days. When you close this window, these details
					will be lost
					<br />
					<br />
					Username: <Input value={userCreatedData.email} /> <br />
					Password: <Input type="password" value={userCreatedData.password} /> <br />
				</ModalBody>
				<ModalFooter>
					<ModalButton onClick={redirectToEdit} size={SIZE.compact} shape={SHAPE.pill}>
						Done
					</ModalButton>
				</ModalFooter>
			</Modal>
		</>
	);
};
export default Page;
