import React from "react";
import { Button, SHAPE, SIZE } from "baseui/button";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faKeyboard } from "@fortawesome/free-solid-svg-icons";
import { NavigationLink } from "../../helpers/BaseLayoutStyles";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

export default (props: any) => {
	let navigate = useNavigate();

	const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

	const navButton_OnClick = () => {
		if (props.url != null) {
			navigate(props.url + "/" + cellValue);
		}
	};

	return (
		<span>
			<NavigationLink
				onClick={() => {
					navButton_OnClick();
				}}
			>
				{props.edit && props.edit == true ? (
					<FontAwesomeIcon icon={faPenToSquare} style={{ paddingRight: ".35rem" }} />
				) : props.keyboard && props.keyboard == true ? (
					<FontAwesomeIcon icon={faKeyboard} style={{ paddingRight: ".35rem" }} />
				) : (
					<FontAwesomeIcon icon={faArrowRight} style={{ paddingRight: ".35rem" }} />
				)}
				{props.text}
			</NavigationLink>
		</span>
	);
};
