/*
	Nav.tsx
	Currently this is a hamburger. 
	We also want a version for a dashboard-style layout with a list of items pre-expanded on the side.
*/

import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import AppContext from "../helpers/AppContext";
import { ControlRow } from "../helpers/BaseLayoutStyles";
import { Drawer, ANCHOR } from "baseui/drawer";
import { Button, SHAPE, SIZE } from "baseui/button";
import { Menu } from "baseui/icon";
import { Card, StyledBody } from "baseui/card";
import { Avatar } from "baseui/avatar";
import { useNavigate } from "react-router-dom";
import config from "../utils/config";
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import NavContent from "./NavContent";

const FixedMenu = styled.div`
	@media screen and (max-width: 1031px) {
		display: none;
	}
	display: block;
	margin: 0 1rem;
	width: 20rem;
	padding-bottom: 1rem;
`;

const NavLayout = (props: any) => {
	const [signingIn, setSigningIn] = useState(false);
	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const appContext = useContext(AppContext);

	useEffect(() => {
		if (appContext.state.auth.authenticated === true) {
			setSigningIn(false);
		}
	}, [appContext]);

	return (
		<>
			<FixedMenu>
				<NavContent loading={props.loading} user={props.user} setMenuIsOpen={props.setMenuIsOpen}></NavContent>
			</FixedMenu>
			<Modal onClose={() => setSigningIn(false)} isOpen={signingIn}>
				<ModalHeader>Log In</ModalHeader>
				<ModalBody>
					<div>
						<FormControl label={() => "Username *"}>
							<Input onChange={(e) => setUserName(e.currentTarget.value)} placeholder="Enter your username" />
						</FormControl>
						<FormControl label={() => "Password *"}>
							<Input
								onChange={(e) => setPassword(e.currentTarget.value)}
								type="password"
								placeholder="Enter your password"
							/>
						</FormControl>

						<ControlRow>
							<Button
								isLoading={appContext.state.auth.isAuthPending}
								onClick={() => {
									appContext.mutate.login(userName, password);
								}}
								size={SIZE.compact}
								shape={SHAPE.pill}
							>
								Log In
							</Button>

							<Button
								kind="secondary"
								onClick={() => {
									setSigningIn(false);
								}}
								size={SIZE.compact}
								shape={SHAPE.pill}
							>
								Cancel
							</Button>
						</ControlRow>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};
export default NavLayout;
