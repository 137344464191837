/*
	Login.tsx
	TODO:
	* Styling, including logo, nice box effects
	* Lost Password option (configurable)
	* 
*/

import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import AppContext from "../../helpers/AppContext";
import { Button } from "baseui/button";
import { MainCard } from "../../helpers/BaseLayoutStyles";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";

const Login = () => {
	const appContext = useContext(AppContext);
	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState("");

	return (
		<MainCard>
			<h1>Login</h1>
			<div>
				<FormControl label={() => "Username *"}>
					<Input
						onChange={(e) => setUserName(e.currentTarget.value)}
						placeholder="Enter your username"
					/>
				</FormControl>
				<FormControl label={() => "Password *"}>
					<Input
						onChange={(e) => setPassword(e.currentTarget.value)}
						type="password"
						placeholder="Enter your password"
					/>
				</FormControl>

				<Button
					isLoading={appContext.state.auth.isAuthPending}
					onClick={() => {
						appContext.mutate.login(userName, password);
					}}
				>
					Log In
				</Button>
			</div>
		</MainCard>
	);
};
export default Login;
