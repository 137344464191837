import { Input } from "baseui/input";
import styled from "styled-components";

// Styled Objects

const CheckboxOverrides = {
	Root: {
		style: ({ $theme }: any) => ({
			marginRight: $theme.sizing.scale600,
		}),
	},
	Label: {
		style: ({ $theme }: any) => ({
			...$theme.typography.font300,
			lineHeight: $theme.sizing.scale800,
		}),
	},
};

const InputOverrides = {
	Root: {
		style: ({ $theme }: any) => ({
			fontSize: "0.75rem",
		}),
	},
	InputContainer: {
		style: ({ $theme }: any) => ({
			// borderRadius: "0px",
			// borderLeft: "0px",
			// borderRight: "0px",
			// borderTop: "0px",
			// borderBottom: "0px",
			padding: "2px",
			height: "30px",

			":focus": {
				borderBottom: "1px solid #ccc",
				outline: "none",
			},
		}),
	},
};

export { InputOverrides };
