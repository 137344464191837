import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AppContext from "../../helpers/AppContext";
import { FlexHeader, ThreeColField, TwoColField } from "../../helpers/BaseLayoutStyles";
import Loading from "../../components/Loading";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Checkbox } from "baseui/checkbox";
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, SIZE } from "baseui/modal";
import Actions from "../../components/Actions";

const TimeModal = (props: any) => {
	const params = useParams() as any;
	const appContext = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [actionPending, setActionPending] = useState(false);
	const [pageData, setPageData] = useState([] as any);
	const [timeData, setTimeData] = useState([] as any);

	let navigate = useNavigate();

	const setTimeDataProperty = async (key: string, value: any, isArray: boolean = false, remove: boolean = false) => {
		let staleData = Object.assign({}, timeData);
		if (Array.isArray(value)) {
			value = value[0];
		}
		staleData[key] = value;

		setTimeData(staleData);
	};

	const saveTime = async () => {
		// Validation logic

		// Build and post the request
		setActionPending(true);

		let result = await appContext.http.request("POST", "/api/timing/dashboard/savetime", timeData, true, false);
		setActionPending(false);

		// Post the request

		if (result.success) {
			toast.success(`Time successfully saved`);
			setTimeData(result.data);

			const field = `run${pageData.column.colDef.runNumber}`;
			//Slet cell = pageData.data[field];

			// let data = pageData.data;
			// data[field] = cell;
			result.data.updatedInModal = true;

			let staleData = Object.assign({}, pageData);
			staleData.data[field] = result.data;
			setPageData(staleData);
			close();
		} else {
			let validationError = result.data.message || result.message || `${result.res.status} ${result.res.statusText}`;
			toast.error(`Error saving time: ${validationError}`);
		}
	};

	function close() {
		props.onClose(pageData);
	}

	useEffect(() => {
		if (props.data && props.data != "") {
			setPageData(props.data);
			if ("runId" in props.data.data) {
				// Coming from the Mobile Popup, where we pass the individual run object
				setTimeData(props.data.data);
			} else {
				// Coming from the Grid, where we pass the entire competitor object
				const field = `run${props.data.column.colDef.runNumber}`;
				let cell = props.data.data[field];
				setTimeData(cell);
			}
		}
	}, [appContext, props]);

	return (
		<>
			<Modal onClose={close} isOpen={props.isOpen} size={SIZE.auto}>
				<ModalHeader>
					{loading == false && (
						<FlexHeader>
							{timeData ? (
								<>
									<h3>{`${timeData.competitorName} - ${timeData.run}`}</h3>
								</>
							) : (
								<h3>Enter Time</h3>
							)}
						</FlexHeader>
					)}
				</ModalHeader>

				<ModalBody>
					{loading == true && <Loading key="loading" />}
					{loading == false && (
						<div>
							{/* TODO: Implement Start/End Times, Penalties, Cones */}

							<FormControl label={() => "Elapsed Time"}>
								<Input
									value={timeData.elapsedTimeDisplay}
									disabled={true}
									onChange={(e) => {
										setTimeDataProperty("elapsedTimeDisplay", e.currentTarget.value);
									}}
								/>
							</FormControl>

							<ThreeColField>
								<div>
									<FormControl label={() => "DNS"}>
										<Checkbox
											checked={timeData.dns}
											onChange={() => {
												setTimeDataProperty("dns", !timeData.dns);
											}}
										/>
									</FormControl>
								</div>
								<div>
									<FormControl label={() => "DNF"}>
										<Checkbox
											checked={timeData.dnf}
											onChange={() => {
												setTimeDataProperty("dnf", !timeData.dnf);
											}}
										/>
									</FormControl>
								</div>
								<div>
									<FormControl label={() => "Wrong Way"}>
										<Checkbox
											checked={timeData.ww}
											onChange={() => {
												setTimeDataProperty("ww", !timeData.ww);
											}}
										/>
									</FormControl>
								</div>
							</ThreeColField>
						</div>
					)}
				</ModalBody>
				<ModalFooter>
					<Actions saveAction={saveTime} saveActionPending={actionPending}></Actions>
				</ModalFooter>
			</Modal>
		</>
	);
};
export default TimeModal;
