/*
	Main.tsx
	Base SPA page for our React app. This controls:
	* User Authentication - whether to display a Login screen or Dashboard with User details
	* Dashboard layout / Nav menu 
	* Routes to individual pages within the SPA site
*/

import React, { useContext, useEffect, useState } from "react";
//import styled from "styled-components";
import { Route, Routes, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import AppContext from "../helpers/AppContext";
import { ContainerBase, ControlRow, FlexFooter, LayoutPanel, MainPanel } from "../helpers/BaseLayoutStyles";
import Loading from "../components/Loading";
import NavTop from "../components/NavTop";
import NavSide from "../components/NavSide";
import Login from "./auth/Login";
import Unavailable from "../layout/Unavailable";
import EventDetail from "./admin/event/EventDetail";
import OrganisationList from "./admin/organisation/OrganisationList";
import OrganisationDetail from "./admin/organisation/OrganisationDetail";
import UserList from "./admin/user/UserList";
import UserDetail from "./admin/user/UserDetail";
import Home from "./public/Home";
import About from "./public/About";
import Result from "./public/Result";
import Search from "./public/Search";
import TimingDashboard from "./timing/TimingDashboard";
import OrganisationHome from "./admin/organisation/OrganisationHome";
import styled from "styled-components";
import Terms from "./public/Terms";
import TimingMobile from "./timing/TimingMobile";
import config from "../utils/config";

const Main = () => {
	const appContext = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [menuIsOpen, setMenuIsOpen] = useState(false);

	let navigate = useNavigate();

	const fetchUserDetails = async () => {
		setLoading(true);

		let result = await appContext.http.request("GET", "/api/user/me", null, true, true);

		setLoading(false);
		if (result.success) {
			setIsError(false);
			appContext.mutate.setUserPreferences(result.data);
		}
	};

	useEffect(() => {
		if (appContext.state.auth.authenticated === false && appContext.state.auth.cookieLoginAttempted === false) {
			appContext.mutate.loginCookie();
		}
		if (appContext.state.auth.authenticated === true && appContext.state.userPreferences.isloggedIn == false) {
			fetchUserDetails();
		}
	}, [appContext]);

	// useEffect(() => {
	// 	document.html.style.height = "100%";
	// 	document.body.style.height = "100%";
	// 	document.body.style.backgroundColor = "yellow";
	// }, []);

	return (
		<LayoutPanel>
			<NavTop
				loading={loading}
				menuIsOpen={menuIsOpen}
				setMenuIsOpen={setMenuIsOpen}
				user={appContext.state.userPreferences}
			></NavTop>
			<MainPanel>
				{isError || config.OFFLINE == true ? (
					<Unavailable />
				) : (
					<>
						<NavSide loading={loading} setMenuIsOpen={setMenuIsOpen} user={appContext.state.userPreferences}></NavSide>

						<Routes>
							<Route path="/admin/organisations/list" element={<OrganisationList />} />
							<Route path="/admin/organisations/edit/:organisationId" element={<OrganisationDetail />} />
							<Route path="/admin/organisations/create" element={<OrganisationDetail />} />
							<Route path="/admin/users/list" element={<UserList />} />
							<Route path="/admin/users/edit/:userId" element={<UserDetail />} />
							<Route path="/admin/users/create/:organisationId" element={<UserDetail />} />
							<Route path="/admin/users/create" element={<UserDetail />} />
							<Route path="/admin/home/:authScopeId" element={<OrganisationHome />} />

							<Route path="/admin/events/edit/:eventId" element={<EventDetail />} />
							<Route path="/admin/events/create/:organisationId/:organisation" element={<EventDetail />} />
							<Route path="/timing/dashboard/:resultId" element={<TimingDashboard />} />
							<Route path="//timing/timing-mobile/:resultId" element={<TimingMobile />} />
							<Route path="/result/:resultId" element={<Result />} />
							<Route path="/about" element={<About />} />
							<Route path="/termsandconditions" element={<Terms />} />
							<Route path="/login" element={<Login />} />
							<Route path="/search" element={<Search />} />
							<Route path="/" element={<Home />} />
						</Routes>
					</>
				)}
			</MainPanel>
		</LayoutPanel>
	);
};

export default Main;
