import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AppContext from "../../../helpers/AppContext";
import {
	FlexHeader,
	ThreeColField,
	TitleH1NoBase,
	TwoColField,
	TwoColRightGutter,
} from "../../../helpers/BaseLayoutStyles";
import Loading from "../../../components/Loading";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Checkbox } from "baseui/checkbox";
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, SIZE } from "baseui/modal";
import Actions from "../../../components/Actions";
import { DatePicker } from "baseui/datepicker";
import { Select } from "baseui/select";
import styled from "styled-components";
import { InputOverrides } from "../../../helpers/BasewebOverrides";

const TwoColNoNameField = styled.div`
	display: grid;
	grid-template-columns: 0.1fr 1fr;
	grid-gap: 1rem;
`;

const Signature = styled.img`
	width: 200px;
`;

const CompetitorDetailModal = (props: any) => {
	const params = useParams() as any;
	const appContext = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [actionPending, setActionPending] = useState(false);
	const [pageData, setPageData] = useState([] as any);
	const [pageMode, setPageMode] = useState("CREATE");
	const [classList, setClassList] = useState([] as any);
	const [paymentMethodList, setPaymentMethodList] = useState([] as any);
	const [activeKey, setActiveKey] = React.useState("0");
	const [competitorId, setCompetitorId] = useState(null as any);

	let navigate = useNavigate();

	const setPageDataProperty = async (key: string, value: any, isArray: boolean = false, remove: boolean = false) => {
		let staleData = Object.assign({}, pageData);

		if (isArray == true) {
			// Push and Pop items from the array
			if (!remove) {
				staleData[key].push(value);
			} else {
				// This assumes that both the value provided and the objects in the array have id fields
				// Find the object matching our id in the array.
				let re = staleData[key].find((e: any) => e.id == value.id);
				if (re != null) {
					// Object found, now remove it.
					const index = staleData[key].indexOf(re, 0);
					if (index > -1) {
						staleData[key].splice(index, 1);
					}
				}
			}
		} else {
			// Just update the value
			if (Array.isArray(value)) {
				value = value[0];
			}
			staleData[key] = value;
		}
		setPageData(staleData);
	};

	const setDynamicClassification = (name: string, value: boolean) => {
		let staleData = Object.assign({}, pageData);

		let classification = staleData.classifications.find((e: { name: string }) => e.name == name);
		if (classification != null) {
			classification.isSelected = value;
		}

		setPageData(staleData);
	};

	const getPageData = async () => {
		setLoading(true);
		let mode = competitorId ? "EDIT" : "CREATE";
		setPageMode(mode);

		// Get Page Data
		let response = await appContext.http.request(
			"GET",
			`/api/admin/competitors/detail/` + (competitorId != null ? competitorId : ""),
			null,
			true,
			true
		);

		if (response.success && response.data && response.data.success) {
			if (mode == "CREATE" && props.eventId && props.eventId != "") {
				response.data.event = { id: props.eventId };
			}
			console.log("Competitor Loading data", response.data);
			setPageData(response.data);

			// Get Lookup options
			let lupResponse = await appContext.http.request(
				"GET",
				// `/api/admin/competitors/detaillookups/${props.eventId}`,
				`/api/admin/competitors/detaillookups/${response.data.event.id}`,
				null,
				true,
				true
			);
			if (lupResponse.success && lupResponse.data && lupResponse.data.success) {
				setClassList(lupResponse.data.classes.items);
				setPaymentMethodList(lupResponse.data.paymentMethods.items);
				if (mode == "CREATE") {
					response.data.number = lupResponse.data.nextNumber;
					setPageData(response.data);
				}
			}
		}

		setLoading(false);
	};

	const saveEvent = async () => {
		// Validation logic

		// Build and post the request
		setActionPending(true);

		let result = await appContext.http.request("POST", "/api/admin/competitors/save", pageData, true, true);
		setActionPending(false);
		if (result.success) {
			toast.success("Changes saved successfully.");
			close();
			// if (competitorId == null) {
			// 	setCompetitorId(result.data.id);
			// }
		}
	};

	function close() {
		props.onClose();
	}

	useEffect(() => {
		if (props.competitorId && props.competitorId != "") {
			setCompetitorId(props.competitorId);
		} else {
			getPageData();
		}
	}, [appContext, props]);

	useEffect(() => {
		if (competitorId) {
			getPageData();
		}
	}, [competitorId]);

	return (
		<>
			<Modal onClose={close} isOpen={props.isOpen} size={SIZE.full}>
				<ModalHeader>
					{loading == false && (
						<FlexHeader>
							{competitorId ? (
								<>
									<TitleH1NoBase>{pageData.name}</TitleH1NoBase>
								</>
							) : (
								<TitleH1NoBase>Create Competitor</TitleH1NoBase>
							)}
						</FlexHeader>
					)}
				</ModalHeader>

				<ModalBody>
					{loading == true && <Loading key="loading" />}
					{loading == false && (
						<div>
							<TwoColRightGutter>
								<div>
									<TwoColNoNameField>
										<div>
											<FormControl label={() => "Number"}>
												<Input
													value={pageData.number}
													type="number"
													onChange={(e) => {
														setPageDataProperty("number", e.currentTarget.value);
													}}
													overrides={InputOverrides}
												/>
											</FormControl>
										</div>
										<div>
											<FormControl label={() => "Name"}>
												<Input
													value={pageData.name}
													onChange={(e) => {
														setPageDataProperty("name", e.currentTarget.value);
													}}
													overrides={InputOverrides}
												/>
											</FormControl>
										</div>
									</TwoColNoNameField>

									<ThreeColField>
										<div>
											<FormControl label={() => "Club"}>
												<Input
													value={pageData.club}
													onChange={(e) => {
														setPageDataProperty("club", e.currentTarget.value);
													}}
													overrides={InputOverrides}
												/>
											</FormControl>
										</div>
										<div>
											<FormControl label={() => "Class"}>
												<Select
													value={pageData.class}
													onChange={(params) => setPageDataProperty("class", params.value)}
													options={classList}
													placeholder=""
												/>
											</FormControl>
										</div>

										<div>
											<FormControl label={() => "Vehicle"}>
												<Input
													value={pageData.vehicle}
													onChange={(e) => {
														setPageDataProperty("vehicle", e.currentTarget.value);
													}}
													overrides={InputOverrides}
												/>
											</FormControl>
										</div>
									</ThreeColField>
								</div>

								<div>
									<FormControl label={() => "Withdrawn"}>
										<Checkbox
											checked={!pageData.active}
											onChange={() => {
												setPageDataProperty("active", !pageData.active);
											}}
										/>
									</FormControl>
									<FormControl label={() => "Exclude From Results"}>
										<Checkbox
											checked={pageData.excludeFromResults}
											onChange={() => {
												setPageDataProperty("excludeFromResults", !pageData.excludeFromResults);
											}}
										/>
									</FormControl>
									{pageData && pageData.classifications && pageData.classifications.length > 0 && (
										<>
											<br />
											<h4>Classifications</h4>
										</>
									)}
									{pageData &&
										pageData.classifications &&
										pageData.classifications.length > 0 &&
										pageData.classifications.map((item: any, index: number) => {
											return (
												<div>
													<FormControl label={() => `${item.name}`}>
														<Checkbox
															checked={item.isSelected}
															onChange={() => {
																setDynamicClassification(item.name, !item.isSelected);
															}}
														/>
													</FormControl>
												</div>
											);
										})}
								</div>
							</TwoColRightGutter>
						</div>
					)}
				</ModalBody>
				<ModalFooter>
					<Actions saveAction={saveEvent} saveActionPending={actionPending}></Actions>
				</ModalFooter>
			</Modal>
		</>
	);
};
export default CompetitorDetailModal;
