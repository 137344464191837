import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import AppContext from "../../helpers/AppContext";
import {
	FlexBody,
	FlexHeader,
	MainCard,
	NavigationLink,
	Row,
	ThreeColField,
	TitleH1,
	TitleH2NoBase,
	TitleH3NoBase,
} from "../../helpers/BaseLayoutStyles";
import Loading from "../../components/Loading";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import DayJS from "dayjs";

import { Tab, Tabs } from "baseui/tabs-motion";
import dayjs from "dayjs";
import { timeEnd } from "console";
import { CellComp } from "ag-grid-community";
import TimeModal from "./TimeModal";
import ModalSelectFromList from "../../components/ModalSelectFromList";
import Actions from "../../components/Actions";
import Breadcrumb from "../../components/Breadcrumb";
import CompetitorDetailModal from "../admin/event/CompetitorDetailModal";
import { Button, SHAPE, SIZE } from "baseui/button";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import CompetitorDetail from "../../components/grid/CompetitorDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAngleRight,
	faArrowAltCircleRight,
	faArrowRight,
	faDownload,
	faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { faFilePdf, faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import config from "../../utils/config";
import { Select } from "baseui/select";

const Filters = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	padding-bottom: 0.5rem;
`;

const ResultsDashboard = (props: any) => {
	//const urlParams = useParams() as any;
	const appContext = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [actionPending, setActionPending] = useState(false);
	const [gridLoading, setGridLoading] = useState(true);
	const [timingData, setTimingData] = useState([] as any);
	const [resultsData, setResultsData] = useState([] as any);

	const [resultsFinalised, setResultsFinalised] = useState(false);

	const [resultId, setResultId] = useState(null as any);
	const [showResultModal, setShowResultModal] = React.useState(false);

	const [sortBy, setSortBy] = useState("Overall");

	let intervalId: any;

	// Grid Configuration
	const [resultsGridApi, setResultsGridApi] = useState(null) as any;
	const [resultsGridColumnApi, setResultsGridColumnApi] = useState(null) as any;
	const onResultsGridReady = (params: any) => {
		setResultsGridApi(params.api);
		setResultsGridColumnApi(params.columnApi);
		//setResultsGridLayout(false);
	};

	let columnDefs: any = [];

	const sortRuns = (a: any, b: any) => {
		if (a.number < b.number) {
			return -1;
		}
		if (a.number > b.number) {
			return 1;
		}
		return 0;
	};

	const setResultsDynamicRunColumnDefs = (api: any, data: any) => {
		if (api != null) {
			columnDefs = [];

			let baseCellStyle: any = {
				lineHeight: "20px",
				fontSize: "12px",
				paddingLeft: "3px",
				paddingRight: "3px",
				paddingTop: "3px",
				paddingBottom: "3px",
			};
			let baseTimeColumnWidth = 75;

			// If the screen is large enough we can make the font and padding larger
			if (window.innerWidth >= 1366) {
				baseCellStyle.fontSize = "14px";
				baseCellStyle.paddingLeft = "5px";
				baseCellStyle.paddingRight = "5px";

				baseTimeColumnWidth = 80;
			}

			columnDefs.push({
				field: "number",
				headerName: "No",
				pinned: "left",
				maxWidth: 50,
				minWidth: 50,
				cellStyle: baseCellStyle,
			});
			columnDefs.push({
				field: "name",
				headerName: "Name",
				pinned: "left",
				maxWidth: 130,
				minWidth: 180,
				cellStyle: baseCellStyle,
			});

			columnDefs.push({
				field: "vehicle",
				headerName: "Vehicle",
				maxWidth: 120,
				minWidth: 120,
				cellStyle: baseCellStyle,
			});
			columnDefs.push({
				field: "class",
				headerName: "Class",
				maxWidth: 55,
				minWidth: 55,
				cellStyle: baseCellStyle,
			});
			// Add our runs
			if (data && data.runs) {
				data.runs.sort(sortRuns).forEach((r: any) => {
					columnDefs.push({
						headerName: r.name,
						maxWidth: baseTimeColumnWidth,
						minWidth: baseTimeColumnWidth,
						valueGetter: (params: any) => {
							return params.data[`run${r.number}`] ? params.data[`run${r.number}`].elapsedTimeDisplay : "";
						},
						cellStyle: (params: any) => {
							const cell = params.data[`run${r.number}`];
							let styles: any = {
								...baseCellStyle,
								backgroundColor: "",
							};
							if (cell) {
								if (sortBy == "Overall" && cell.progressOverall == true) {
									// Elimination - these cars progress to the next round
									styles.backgroundColor = "#efefef";
								} else if (sortBy == "Class" && cell.progressClass == true) {
									// Elimination - these cars progress to the next round
									styles.backgroundColor = "#efefef";
								}
								if (cell.highlightTime == true) {
									styles.fontWeight = "bold";
								}
							}
							return styles;
						},

						editable: false,
						runId: r.id,
						runNumber: r.number,
					});
				});
			}

			if (data.resultType == "FASTEST") {
				columnDefs.push({
					field: "fastest",
					headerName: "Fastest",
					maxWidth: baseTimeColumnWidth,
					minWidth: baseTimeColumnWidth,
					cellStyle: baseCellStyle,
				});
			}
			if (data.resultType == "AGGREGATE") {
				columnDefs.push({
					field: "total",
					headerName: "Total",
					maxWidth: baseTimeColumnWidth,
					minWidth: baseTimeColumnWidth,
					cellStyle: baseCellStyle,
				});
			}
			columnDefs.push({
				field: "positionOverall",
				headerName: "O/A",
				headerClass: "text-center",
				maxWidth: 55,
				minWidth: 55,
				cellStyle: {
					...baseCellStyle,
					textAlign: "center",
				},
			});
			columnDefs.push({
				field: "positionClass",
				headerName: "Cls",
				headerClass: "text-center",
				maxWidth: 55,
				minWidth: 55,
				cellStyle: {
					...baseCellStyle,
					textAlign: "center",
				},
			});

			// if (resultsFinalised == true && data.classifications && data.classifications.length > 0) {
			// 	data.classifications.forEach((r: any) => {
			// 		columnDefs.push({
			// 			field: r.code,
			// 			headerName: r.code,
			// 			minWidth: 65,
			// 			maxWidth: 65,
			// 		});
			// 	});
			// }

			api.setColumnDefs(columnDefs);
			api.setRowData(resultsData.competitors);
		}
	};

	// const setResultsGridLayout = (print: boolean) => {
	// 	if (resultsGridApi != null) {
	// 		if (print == true) {
	// 			resultsGridApi.setDomLayout("print");
	// 		} else {
	// 			resultsGridApi.setDomLayout(null);
	// 		}

	// 		// // Resize grid
	// 		// const allColumnIds = [] as any;
	// 		// if (resultsGridColumnApi != null) {
	// 		// 	let cols = resultsGridColumnApi.getAllColumns();
	// 		// 	if (cols != null) {
	// 		// 		cols.forEach((column: any) => {
	// 		// 			allColumnIds.push(column.colId);
	// 		// 		});
	// 		// 		resultsGridColumnApi.autoSizeColumns(allColumnIds, false);
	// 		// 	}
	// 		// }
	// 	}
	// };

	let navigate = useNavigate();

	const getResultsData = async () => {
		if (resultId != null) {
			setGridLoading(true);

			let response = await appContext.http.request(
				"GET",
				`/api/public/results/get/${resultId}/${sortBy}`,
				null,
				true,
				true
			);

			if (response.success && response.data && response.data.success) {
				setResultsData(response.data);
				//setResultsGridLayout(false);
			}
			setLoading(false);
			setGridLoading(false);
		}
	};

	const printResult = async () => {
		if (resultId != null) {
			let response = await appContext.http.downloadRequest(
				"GET",
				`/api/public/results/print/${resultId}/${sortBy}`,
				null,
				true,
				true
			);
		}
	};

	useEffect(() => {
		console.log("Show Results Modal: " + props.resultId);
		setResultId(props.resultId);
	}, [appContext, props.resultId]);

	useEffect(() => {
		// Retrieve pageData when resultId is set
		getResultsData();
	}, [resultId]);

	useEffect(() => {
		setResultsDynamicRunColumnDefs(resultsGridApi, resultsData);
	}, [resultsGridApi, resultsData]);

	useEffect(() => {
		if (!resultsFinalised) {
			intervalId = setInterval(() => {
				console.log("Timer fired");
				getResultsData();
			}, 60000);
			return () => clearInterval(intervalId);
		} else {
			if (intervalId != null) {
				clearInterval(intervalId);
			}
		}
	}, [resultsData, resultsFinalised]);

	useEffect(() => {
		getResultsData();
	}, [sortBy]);

	const styles = `
	.ag-header-cell-label .ag-header-cell-text {
		font-size: 12px;
	}

	.ag-header-cell.text-center {
		.ag-header-cell-label {
		  justify-content: center;
		}
	  }
	
	.ag-header-cell {
		padding: 6px;
		padding-left: 6px !important;
		padding-right: 6px !important;
	}

	`;

	return (
		<>
			<div>
				{loading == true && <Loading key="loading" />}
				{loading == false && (
					<div>
						<style>{styles}</style>
						<FlexBody>
							{resultsData && resultsData.resultType && (
								<TitleH2NoBase className="center">
									{sortBy} Results {resultsFinalised && <span> (Final Results)</span>}
								</TitleH2NoBase>
							)}
							<Filters>
								<div>
									<Select
										options={[
											{ label: "Overall", id: "Overall" },
											{ label: "Class", id: "Class" },
										]}
										labelKey="label"
										valueKey="id"
										value={[{ id: sortBy }]}
										onChange={(params: any) => {
											setSortBy(params.value[0].id);
										}}
										maxDropdownHeight="200px"
										clearable={false}
										searchable={false}
										size={SIZE.compact}
									/>
								</div>
								<div style={{ textAlign: "right", paddingTop: ".45rem", paddingLeft: ".75rem" }}>
									<NavigationLink
										onClick={() => {
											printResult();
										}}
									>
										<FontAwesomeIcon icon={faFilePdf} style={{ paddingRight: ".25rem" }} /> Download
									</NavigationLink>
								</div>
							</Filters>

							<div className="ag-theme-balham" style={{ height: 400, width: "100%" }}>
								<AgGridReact
									frameworkComponents={{
										competitorDetail: CompetitorDetail,
									}}
									rowData={resultsData.competitors}
									defaultColDef={{
										flex: 1,
										minWidth: 100,
										editable: false,
										resizable: true,
										sortable: true,
										filter: true,
									}}
									columnDefs={columnDefs}
									onGridReady={onResultsGridReady}
									//onFirstDataRendered={() => setResultsGridLayout(false)}
								></AgGridReact>
							</div>

							<Row>
								<div>
									<Button
										kind="secondary"
										size={SIZE.compact}
										shape={SHAPE.pill}
										onClick={() => {
											getResultsData();
										}}
									>
										<FontAwesomeIcon icon={faSpinner} style={{ paddingRight: ".35rem" }} />
										Refresh
									</Button>{" "}
								</div>
							</Row>
						</FlexBody>
					</div>
				)}
			</div>
		</>
	);
};
export default ResultsDashboard;
