import dayjs from "dayjs";

export default (props: any) => {
	return (
		<span>
			<b>{props.data.name}</b>
			<br />
			{props.data.venue}
		</span>
	);
};
