import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../../helpers/AppContext";
import { FlexBody, FlexHeader, MainCard, TitleH1 } from "../../helpers/BaseLayoutStyles";
import Breadcrumb from "../../components/Breadcrumb";

const Page = () => {
	const appContext = useContext(AppContext);
	const [breadcrumbs, setBreadcrumbs] = useState(null) as any;

	useEffect(() => {
		setBreadcrumbs([
			{ name: "BDA", path: "/" },
			{ name: "About", path: "/about" },
		]);
	}, [appContext]);

	return (
		<MainCard>
			<FlexHeader>
				<div />
				<TitleH1>About BDA</TitleH1>
			</FlexHeader>
			<FlexBody>
				<div>
					BDA Results was created by long-time motorsport competitor and event volunteer Chris Subritzky, to provide a
					simple method for clubs to enter results for Clubsport events and make them immediately available to
					competitors online.
				</div>
				<div>&nbsp;</div>
				<div>
					As a service to NZ Clubsport, the system is available free of charge for any Motorsport NZ affiliated clubs to
					use for running their Clubsport events. If your club is interested in using BDA please contact us at{" "}
					<a href="email:hello@codefoundry.nz">hello@codefoundry.nz</a>.
				</div>
				<div>&nbsp;</div>
				<div>
					Use of this website is controlled by the <a href="/termsandconditions">Terms and Conditions</a> of use.
				</div>
			</FlexBody>
		</MainCard>
	);
};
export default Page;
