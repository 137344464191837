import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import AppContext from "../../../helpers/AppContext";
import {
	ControlRow,
	FlexBody,
	FlexHeader,
	Footer,
	FooterBox,
	MainCard,
	MainPanel,
	NavigationLink,
	StyledTableRow,
	ThreeColField,
	TitleH1,
	TitleH3,
	TwoColField,
} from "../../../helpers/BaseLayoutStyles";
import Loading from "../../../components/Loading";
import EventCard from "../../../components/EventCard";
import Actions from "../../../components/Actions";
import Breadcrumb from "../../../components/Breadcrumb";
import { Tabs, Tab } from "baseui/tabs-motion";
import dayjs from "dayjs";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import NavButton from "../../../components/grid/NavButton";
import ModalButton from "../../../components/grid/ModalButton";
import DeleteButton from "../../../components/grid/DeleteButton";
import GridCheckbox from "../../../components/grid/Checkbox";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import ModalImageUpload from "../../../components/ModalImageUpload";
import config from "../../../utils/config";

const CardContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	height: 430px;

	overflow-y: auto;
`;

const TabContainer = styled.div`
	display: block;
	overflow-y: none;
	width: 100%;
	height: 450px;
`;

const Page = () => {
	const urlParams = useParams() as any;
	const appContext = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [actionPending, setActionPending] = useState(false);
	const [pageData, setPageData] = useState([] as any);
	const [pageMode, setPageMode] = useState("EDIT");
	const [rolesList, setRolesList] = useState([] as any);
	const [activeKey, setActiveKey] = React.useState("0");
	const [uploadModalOpen, setUploadModalOpen] = useState(false);

	// Grid Configuration
	const [eventGridApi, setEventGridApi] = useState(null) as any;
	const [eventGridColumnApi, setEventGridColumnApi] = useState(null);
	const onEventGridReady = (params: any) => {
		setEventGridApi(params.api);
		setEventGridColumnApi(params.columnApi);
	};

	const [userGridApi, setUserGridApi] = useState(null) as any;
	const [userGridColumnApi, setUserGridColumnApi] = useState(null);
	const onUserGridReady = (params: any) => {
		setUserGridApi(params.api);
		setUserGridColumnApi(params.columnApi);
	};

	const [classificationsGridApi, setClassificationsGridApi] = useState(null) as any;
	const [classificationsGridColumnApi, setClassificationsGridColumnApi] = useState(null) as any;
	const onClassificationsGridReady = (params: any) => {
		setClassificationsGridApi(params.api);
		setClassificationsGridColumnApi(params.columnApi);
		setClassificationsGridLayout(false);
	};

	const setClassificationsGridLayout = (print: boolean) => {
		if (print == null) {
			print = false;
		}
		if (classificationsGridApi != null) {
			if (print == true) {
				classificationsGridApi.setDomLayout("print");
			} else {
				classificationsGridApi.setDomLayout(null);
			}

			// Resize grid
			const allColumnIds = [] as any;
			if (classificationsGridColumnApi != null) {
				let cols = classificationsGridColumnApi.getAllColumns();
				if (cols != null) {
					cols.forEach((column: any) => {
						allColumnIds.push(column.colId);
					});
					classificationsGridColumnApi.autoSizeColumns(allColumnIds, false);
				}
			}
		}
	};

	const [classGridApi, setClassGridApi] = useState(null) as any;
	const [classGridColumnApi, setClassGridColumnApi] = useState(null) as any;
	const onClassGridReady = (params: any) => {
		setClassGridApi(params.api);
		setClassGridColumnApi(params.columnApi);
		setClassGridLayout(false);
	};

	const setClassGridLayout = (print: boolean) => {
		if (print == null) {
			print = false;
		}
		if (classGridApi != null) {
			if (print == true) {
				classGridApi.setDomLayout("print");
			} else {
				classGridApi.setDomLayout(null);
			}

			// Resize grid
			const allColumnIds = [] as any;
			if (classGridColumnApi != null) {
				let cols = classGridColumnApi.getAllColumns();
				if (cols != null) {
					cols.forEach((column: any) => {
						allColumnIds.push(column.colId);
					});
					classGridColumnApi.autoSizeColumns(allColumnIds, false);
				}
			}
		}
	};

	let navigate = useNavigate();

	const getPageData = async () => {
		setLoading(true);

		// Ensure we don't pass through organisationId if it's undefined or null
		let response = await appContext.http.request(
			"GET",
			`/api/admin/organisations/asdetail/` + (urlParams.authScopeId != null ? urlParams.authScopeId : ""),
			null,
			true,
			true
		);

		setLoading(false);
		if (response.success && response.data && response.data.success) {
			setPageData(response.data);
		}
	};

	const setPageDataProperty = async (key: string, value: any, remove: boolean = false) => {
		let staleData = Object.assign({}, pageData);

		if (Array.isArray(staleData[key])) {
			// Push and Pop items from the array
			if (!remove) {
				staleData[key].push(value);
			} else {
				// This assumes that both the value provided and the objects in the array have id fields
				// Find the object matching our id in the array.
				let re = staleData[key].find((e: any) => e.id == value.id);
				if (re != null) {
					// Object found, now remove it.
					const index = staleData[key].indexOf(re, 0);
					if (index > -1) {
						staleData[key].splice(index, 1);
					}
				}
			}
		} else {
			// Just update the value
			staleData[key] = value;
		}
		setPageData(staleData);
	};

	const addClassificationRow = () => {
		let staleData = Object.assign({}, pageData);
		pageData.classifications.push({ active: true });
		setPageData(staleData);
		classificationsGridApi.setRowData(pageData.classifications);
	};

	const addClassRow = () => {
		let staleData = Object.assign({}, pageData);
		pageData.classes.push({ active: true });
		setPageData(staleData);
		classGridApi.setRowData(pageData.classes);
	};

	const closeUploadModal = () => {
		setUploadModalOpen(false);
		getPageData();
	};

	const saveOrganisation = async () => {
		// Validation logic

		// Build and post the request
		setActionPending(true);

		let result = await appContext.http.request("POST", "/api/admin/organisations/assave", pageData, true, true);
		setActionPending(false);
		if (result.success) {
			toast.success("Changes saved successfully.");
			getPageData();
		}
	};

	useEffect(() => {
		if (urlParams.authScopeId && urlParams.authScopeId != "") {
			setPageMode("EDIT");
		}

		getPageData();
	}, [appContext, urlParams.authScopeId]);

	return (
		<>
			<MainCard>
				{loading == false && (
					<>
						<Breadcrumb breadcrumbs={pageData.breadcrumbs} />

						<FlexHeader>
							{urlParams.authScopeId && (
								<>
									<TitleH1>{pageData.name}</TitleH1>
									<div className="responsive">
										{pageData && pageData.logoUrl && (
											<img src={`${config.API_URL}${pageData.logoUrl}`} alt="Logo" style={{ maxHeight: "40px" }} />
										)}
									</div>
									<div></div>
								</>
							)}
						</FlexHeader>
					</>
				)}
				<FlexBody className="flexBody100">
					{loading == true && <Loading key="loading" />}
					{loading == false && (
						<Tabs
							activeKey={activeKey}
							onChange={({ activeKey }) => {
								setActiveKey(activeKey.toString());
							}}
							activateOnFocus
						>
							<Tab title="Current Events">
								<TabContainer>
									<TitleH3>Recent and Coming Events</TitleH3>
									<CardContainer>
										{pageData.events &&
											pageData.events.filter((e: any) => e.isCurrentOrRecent == true && e.active == true).length ===
												0 && <div>No recent or upcoming events to display.</div>}
										{pageData.events &&
											pageData.events
												.filter((e: any) => e.isCurrentOrRecent == true && e.active == true)
												.map((item: any) => {
													return <EventCard data={item} />;
												})}
									</CardContainer>
								</TabContainer>
								<Footer>
									<FooterBox>
										<Actions addUrl={`/admin/events/create/${pageData.id}/${pageData.name}`} addTitle="Event"></Actions>
									</FooterBox>
								</Footer>
							</Tab>

							<Tab title={"All Events" + (pageData.events && ` ( ${pageData.events.length} ) `)}>
								<div className="ag-theme-alpine" style={{ height: 460, width: "100%" }}>
									<AgGridReact
										rowData={pageData.events}
										frameworkComponents={{
											navButtonRenderer: NavButton,
											navCheckboxRenderer: GridCheckbox,
										}}
										defaultColDef={{
											flex: 1,
											minWidth: 100,
											editable: false,
											resizable: true,
											sortable: true,
											filter: true,
										}}
										onGridReady={onEventGridReady}
										pagination={true}
										paginationPageSize={10}
										onRowClicked={(e: any) => navigate(`/admin/events/edit/${e.data.id}`)}
									>
										<AgGridColumn field="name" />
										<AgGridColumn field="venue" />
										<AgGridColumn field="eventType" headerName="Type" />
										<AgGridColumn
											field="eventDate"
											headerName="Date"
											cellRenderer={(data: any) => {
												return data.value ? dayjs(data.value).format("DD MMM YYYY") : "";
											}}
										/>
										<AgGridColumn
											field="id"
											cellRenderer="navButtonRenderer"
											headerName=""
											cellRendererParams={{ text: "View Event", url: "/admin/events/edit" }}
										/>
									</AgGridReact>
									<Actions addUrl={`/admin/events/create/${pageData.id}/${pageData.name}`} addTitle="Event"></Actions>
								</div>
							</Tab>

							<Tab title="Club Details">
								<ThreeColField>
									<div>
										<FormControl label={() => "Name"}>
											<Input
												value={pageData.name}
												onChange={(e) => {
													setPageDataProperty("name", e.currentTarget.value);
												}}
											/>
										</FormControl>

										<FormControl label={() => "Identifier"}>
											<Input
												value={pageData.slug}
												onChange={(e) => {
													setPageDataProperty("slug", e.currentTarget.value);
												}}
												disabled={true}
											/>
										</FormControl>

										<FormControl label={() => "Logo"}>
											<>
												<div>
													{pageData && pageData.logoUrl ? (
														<img
															src={`${config.API_URL}${pageData.logoUrl}`}
															alt="Logo"
															style={{ maxHeight: "200px" }}
														/>
													) : (
														<div
															style={{
																width: "200px",
																height: "110px",
																fontStyle: "italic",
																display: "flex",
																justifyContent: "center",
																paddingTop: "80px",
																verticalAlign: "middle",
																border: "solid black 1px",
															}}
														>
															No logo set
														</div>
													)}
												</div>
												<NavigationLink
													onClick={() => {
														setUploadModalOpen(true);
													}}
												>
													Change Logo
												</NavigationLink>
												<ModalImageUpload
													isOpen={uploadModalOpen}
													onClose={closeUploadModal}
													setIsOpen={setUploadModalOpen}
													title="Upload Organisation Logo"
													onAdd={(value: any) => {
														getPageData();
													}}
													api="/api/admin/organisations/uploadlogo"
													parentId={pageData.id}
												></ModalImageUpload>
											</>
										</FormControl>
									</div>

									<div>
										<FormControl
											label={() => "Default Classes"}
											caption={() =>
												"Default classes to use when creating new Events. When uploading competitors the class set for each competitor should match either the Code or Name of one of these classes."
											}
										>
											<>
												<div className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
													<AgGridReact
														rowData={pageData.classes}
														frameworkComponents={{
															navButtonRenderer: NavButton,
															deleteButtonRenderer: DeleteButton,
														}}
														defaultColDef={{
															flex: 1,
															minWidth: 100,
															editable: true,
															resizable: true,
															sortable: true,
															filter: true,
														}}
														onGridReady={onClassGridReady}
														onFirstDataRendered={() => setClassGridLayout(false)}
													>
														<AgGridColumn field="code" />
														<AgGridColumn field="name" />

														<AgGridColumn
															field="id"
															editable={false}
															cellRenderer="deleteButtonRenderer"
															headerName=""
														/>
													</AgGridReact>
												</div>
											</>
										</FormControl>
										<Actions addAction={addClassRow} addTitle="Class"></Actions>
									</div>

									<div>
										<FormControl
											label={() => "Result Classifications"}
											caption={() =>
												"These are additional categories that can be set against competitors and calculated in results. eg: Junior Driver, Production Car. A maximum of three Classifications can be configured."
											}
										>
											<>
												<div className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
													<AgGridReact
														rowData={pageData.classifications}
														frameworkComponents={{
															navButtonRenderer: NavButton,
															deleteButtonRenderer: DeleteButton,
														}}
														defaultColDef={{
															flex: 1,
															minWidth: 100,
															editable: true,
															resizable: true,
															sortable: true,
															filter: true,
														}}
														onGridReady={onClassificationsGridReady}
														onFirstDataRendered={() => setClassificationsGridLayout(false)}
													>
														<AgGridColumn field="code" />
														<AgGridColumn field="name" minWidth={200} />

														<AgGridColumn
															field="id"
															editable={false}
															cellRenderer="deleteButtonRenderer"
															headerName=""
														/>
													</AgGridReact>
												</div>
											</>
										</FormControl>
										<Actions addAction={addClassificationRow} addTitle="Classification"></Actions>
									</div>
								</ThreeColField>
								<Actions saveAction={saveOrganisation} saveActionPending={actionPending}></Actions>
							</Tab>

							<Tab title={"Users" + (pageData.users && ` ( ${pageData.users.length} ) `)}>
								<div className="ag-theme-alpine" style={{ height: 460, width: "100%" }}>
									<AgGridReact
										rowData={pageData.users}
										frameworkComponents={{
											navButtonRenderer: NavButton,
											navCheckboxRenderer: GridCheckbox,
										}}
										defaultColDef={{
											flex: 1,
											minWidth: 100,
											editable: false,
											resizable: true,
											sortable: true,
											filter: true,
										}}
										onGridReady={onUserGridReady}
										pagination={true}
										paginationPageSize={10}
										onRowClicked={(e: any) => navigate(`/admin/users/edit/${e.data.id}`)}
									>
										<AgGridColumn field="name" />
										<AgGridColumn field="active" cellRenderer="navCheckboxRenderer" />

										<AgGridColumn
											field="id"
											cellRenderer="navButtonRenderer"
											headerName=""
											cellRendererParams={{ text: "Details", url: "/admin/users/edit", edit: true }}
										/>
									</AgGridReact>
									<Actions addUrl={`/admin/users/create/${pageData.id}`} addTitle="User"></Actions>
								</div>
							</Tab>
						</Tabs>
					)}
				</FlexBody>
			</MainCard>
		</>
	);
};
export default Page;
