import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import AppContext from "../../../helpers/AppContext";
import { StyledTable, StyledHeadCell, StyledBodyCell } from "baseui/table-grid";
import { MainCard, FlexHeader, StyledTableRow, FlexFooter, FlexBody, TitleH1 } from "../../../helpers/BaseLayoutStyles";
import Loading from "../../../components/Loading";
import { useStyletron } from "styletron-react";
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import NavButton from "../../../components/grid/NavButton";
import Checkbox from "../../../components/grid/Checkbox";
import Actions from "../../../components/Actions";
import Breadcrumb from "../../../components/Breadcrumb";

const Page = () => {
	const appContext = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [actionPending, setActionPending] = useState(false);
	const [users, setUsers] = useState(null as any);
	const [breadcrumbs, setBreadcrumbs] = useState(null) as any;
	const [css] = useStyletron();

	// Grid Configuration
	const [gridApi, setGridApi] = useState(null) as any;
	const [gridColumnApi, setGridColumnApi] = useState(null);
	const onGridReady = (params: any) => {
		setGridApi(params.api);
		setGridColumnApi(params.columnApi);
	};
	let navigate = useNavigate();

	const getPageData = async () => {
		setLoading(true);

		let response = await appContext.http.request(
			"GET",
			`/api/admin/user/list`, // /api/theapitocall/${id} if doing a GET for a specific id
			null,
			true,
			true
		);

		setLoading(false);
		if (response.success) {
			// Set State objects
			setUsers(response.data.users);
			setBreadcrumbs(response.data.breadcrumbs);
		}
	};

	useEffect(() => {
		getPageData();
	}, [appContext]);

	return (
		<MainCard>
			<FlexHeader>
				<Breadcrumb breadcrumbs={breadcrumbs} />
				<div className="responsive" />
				<TitleH1>Users</TitleH1>
			</FlexHeader>

			<FlexBody className="flexBody100">
				{loading == true && <Loading key="loading"></Loading>}

				{loading == false && (
					<div className="ag-theme-alpine" style={{ height: "95%", width: "100%" }}>
						<AgGridReact
							rowData={users}
							frameworkComponents={{
								navButtonRenderer: NavButton,
								navCheckboxRenderer: Checkbox,
							}}
							defaultColDef={{
								flex: 1,
								minWidth: 100,
								editable: false,
								resizable: true,
								sortable: true,
								filter: true,
							}}
							onGridReady={onGridReady}
							pagination={true}
							paginationPageSize={10}
						>
							<AgGridColumn field="name" />
							<AgGridColumn field="email" />
							<AgGridColumn field="active" cellRenderer="navCheckboxRenderer" />

							<AgGridColumn
								field="id"
								cellRenderer="navButtonRenderer"
								headerName=""
								cellRendererParams={{ text: "Details", url: "/admin/users/edit" }}
							/>
						</AgGridReact>
						<Actions addUrl={`/admin/users/create`} addTitle="User" />
					</div>
				)}
			</FlexBody>
		</MainCard>
	);
};
export default Page;
