import React from "react";
import { Button, SHAPE, SIZE } from "baseui/button";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { NavigationLink } from "../../helpers/BaseLayoutStyles";

export default (props: any) => {
	let navigate = useNavigate();

	const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

	const deleteButton_OnClick = () => {
		alert("Delete row " + props.value + ". Not yet implemented!");
	};

	return (
		<span>
			<NavigationLink
				onClick={() => {
					deleteButton_OnClick();
				}}
			>
				<FontAwesomeIcon icon={faTrashCan} style={{ paddingRight: ".35rem" }} /> Delete
			</NavigationLink>
		</span>
	);
};
