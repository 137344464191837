import React from "react";
import { Checkbox } from "baseui/checkbox";

export default (props: any) => {
	const checkedHandler = (value: boolean) => {
		let checked = value; //target.checked;
		let colId = props.column.colId;
		props.node.setDataValue(colId, checked);
	};
	return (
		<span>
			<Checkbox
				checked={props.value}
				onChange={() => {
					checkedHandler(!props.value);
					// props.toggleCheckbox(!props.value);
					// setPageDataProperty("active", !pageData.active);
				}}
			/>
		</span>
	);
};
