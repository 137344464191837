import dayjs from "dayjs";
import React, { useState } from "react";
import styled from "styled-components";
import { TwoColField } from "../../helpers/BaseLayoutStyles";
import { Button, SHAPE, SIZE } from "baseui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

const Result = styled.div`
	font-size: 12px;
`;

const TimesGrid = styled.div`
	flex: 1 1 auto;
	display: grid;
	grid-template-columns: auto auto;
	grid-gap: 1rem;
	margin: 0.1rem;
`;

// Position the span on the right of the screen
const Arrows = styled.span`
	position: absolute;
	right: 0;
	top: 0;
`;

export default (props: any) => {
	const [showResult, setShowResult] = useState(false);

	return (
		<>
			{props.data.name && (
				<>
					<span>
						<a
							onClick={() => {
								setShowResult(!showResult);
							}}
						>
							<span>{props.data.name}</span>
							<Arrows>
								{!showResult && <FontAwesomeIcon icon={faSortDown} style={{ paddingRight: ".35rem" }} />}
								{showResult && <FontAwesomeIcon icon={faSortUp} style={{ paddingRight: ".35rem" }} />}
							</Arrows>
							{props.showVehicle && (
								<>
									<br />
									{props.data.vehicle}
								</>
							)}
							<br />

							{showResult && (
								<div>
									{props.runs.map((r: any) => {
										//const cell = params.data[`run${r.number}`];
										// 				let styles: any = {
										// 					backgroundColor: "",
										// 					fontSize: fontSize,
										// 				};
										// 				if (cell) {
										// 					if (sortBy == "Overall" && cell.progressOverall == true) {
										// 						// Elimination - these cars progress to the next round
										// 						styles.backgroundColor = "#efefef";
										// 					} else if (sortBy == "Class" && cell.progressClass == true) {
										// 						// Elimination - these cars progress to the next round
										// 						styles.backgroundColor = "#efefef";
										// 					}
										// 					if (cell.highlightTime == true) {
										// 						styles.fontWeight = "bold";
										// 					}
										// 				}
										return (
											<TimesGrid>
												<Result>{r.name}</Result>
												<Result>
													{props.data[`run${r.number}`] ? props.data[`run${r.number}`].elapsedTimeDisplay : ""}
												</Result>
											</TimesGrid>
										);
									})}
								</div>
							)}
						</a>
					</span>
				</>
			)}
		</>
	);
};
