import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import AppContext from "../../helpers/AppContext";
import { FlexBody, FlexHeader, FlexHeaderFullWidth, MainCard, TitleH1, TitleH3 } from "../../helpers/BaseLayoutStyles";
import Loading from "../../components/Loading";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import NavLink from "../../components/grid/NavLink";
import ResultNameVenue from "../../components/grid/ResultNameVenue";
import ResultDateOrganisation from "../../components/grid/ResultDateOrganisation";
import { FormControl } from "baseui/form-control";
import dayjs from "dayjs";
import Breadcrumb from "../../components/Breadcrumb";

const Page = () => {
	const appContext = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [actionPending, setActionPending] = useState(false);

	const [recentResults, setRecentResults] = useState(null as any);
	const [breadcrumbs, setBreadcrumbs] = useState(null) as any;

	// Grid Configuration
	const [gridApi, setGridApi] = useState(null) as any;
	const [gridColumnApi, setGridColumnApi] = useState(null) as any;
	const onGridReady = (params: any) => {
		setGridApi(params.api);
		setGridColumnApi(params.columnApi);
		setColumnDefs(params.api);
		setGridLayout(false);
	};

	let columnDefs: any = [];

	const setColumnDefs = (api: any) => {
		if (api != null) {
			columnDefs = [];

			let baseCellStyle: any = {
				// lineHeight: "20px",
				// fontSize: "12px",
				// paddingLeft: "3px",
				// paddingRight: "3px",
				// paddingTop: "3px",
				// paddingBottom: "3px",
			};

			// If the screen is large enough we can make the font and padding larger
			if (window.innerWidth < 1366) {
				baseCellStyle.fontSize = "12px";
				baseCellStyle.paddingLeft = "3px";
				baseCellStyle.paddingRight = "3px";
			}

			columnDefs.push({
				cellRenderer: "resultDateOrganisation",
				headerName: "Date",
				minWidth: 110,
				maxWidth: 110,
				autoHeight: true,
				cellStyle: baseCellStyle,
			});

			columnDefs.push({
				cellRenderer: "resultNameVenue",
				headerName: "Event",
				autoHeight: true,
				cellStyle: baseCellStyle,
			});

			api.setColumnDefs(columnDefs);
			//api.setRowData(pageData.competitors);
		}
	};

	const setGridLayout = (print: boolean) => {
		if (gridApi != null) {
			if (print == true) {
				gridApi.setDomLayout("print");
			} else {
				gridApi.setDomLayout(null);
			}

			// This may be a better way of doing grid sizing
			// Set min and max column widths on columns we care about, then everything can resize dynamically up to those maximums
			gridApi.sizeColumnsToFit();
		}
	};

	let navigate = useNavigate();

	const getPageData = async () => {
		setLoading(true);

		let response = await appContext.http.request("GET", `/api/public/home/recentresults`, null, true, false);

		setLoading(false);
		if (response.success && response.data && response.data.success) {
			setRecentResults(response.data.results);
			setGridLayout(false);
		} else {
			// Fail silently, log to the console for debugging purposes
			console.log(response);
			setRecentResults({});
		}
	};

	useEffect(() => {
		getPageData();
	}, [appContext]);

	useEffect(() => {
		setBreadcrumbs([
			{ name: "BDA", path: "/" },
			{ name: "Clubsport Results", path: "/" },
		]);
	}, [appContext]);

	const styles = `
	@media only screen and (max-width: 767px) {
		.ag-header-cell-label .ag-header-cell-text {
			font-size: 12px;
		}

		.ag-header-cell.text-center {
			.ag-header-cell-label {
			justify-content: center;
			}
		}

		.ag-header-cell {
			padding: 6px;
			padding-left: 6px !important;
			padding-right: 6px !important;
		}

		.ag-react-container {
			font-size: 12px;
		}
	}
	`;

	return (
		<MainCard>
			<style>{styles}</style>
			<FlexHeaderFullWidth>
				<TitleH1>Clubsport Results</TitleH1>
				<TitleH3>Results of current and recent events.</TitleH3>
			</FlexHeaderFullWidth>
			<FlexBody className="flexBody150">
				{loading == true && <Loading key="loading"></Loading>}
				{loading == false && (
					<div className="ag-theme-balham" style={{ height: "100%", width: "100%" }}>
						<AgGridReact
							rowData={recentResults}
							frameworkComponents={{
								navLinkRenderer: NavLink,
								resultNameVenue: ResultNameVenue,
								resultDateOrganisation: ResultDateOrganisation,
							}}
							defaultColDef={{
								flex: 1,
								minWidth: 100,
								editable: false,
								resizable: false,
								sortable: true,
								filter: true,
							}}
							onGridReady={onGridReady}
							onRowClicked={(e) => navigate(`/result/${e.data.id}`)}
							onFirstDataRendered={() => setGridLayout(false)}
							pagination={true}
							paginationPageSize={10}
							columnDefs={columnDefs}
						></AgGridReact>
					</div>
				)}
			</FlexBody>
		</MainCard>
	);
};
export default Page;
