import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import AppContext from "../../../helpers/AppContext";
import {
	ControlRow,
	FlexHeader,
	MainCard,
	StyledTableRow,
	ThreeColField,
	TitleH1NoBase,
	TwoColField,
} from "../../../helpers/BaseLayoutStyles";
import Loading from "../../../components/Loading";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Checkbox } from "baseui/checkbox";
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import { Tag } from "baseui/tag";
import ModalSelectFromList from "../../../components/ModalSelectFromList";
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal";
import { StyledTable, StyledHeadCell, StyledBodyCell } from "baseui/table-grid";
import { Check, Delete } from "baseui/icon";
import Actions from "../../../components/Actions";
import Breadcrumb from "../../../components/Breadcrumb";
import DataGrid from "react-data-grid";
import { Tabs, Tab } from "baseui/tabs-motion";
import dayjs from "dayjs";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import NavButton from "../../../components/grid/NavButton";
import GridCheckbox from "../../../components/grid/Checkbox";

const Page = () => {
	const urlParams = useParams() as any;
	const appContext = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [actionPending, setActionPending] = useState(false);
	const [pageData, setPageData] = useState([] as any);
	const [pageMode, setPageMode] = useState("CREATE");
	const [rolesList, setRolesList] = useState([] as any);
	const [activeKey, setActiveKey] = React.useState("0");

	// Grid Configuration
	const [eventGridApi, setEventGridApi] = useState(null) as any;
	const [eventGridColumnApi, setEventGridColumnApi] = useState(null);
	const onEventGridReady = (params: any) => {
		setEventGridApi(params.api);
		setEventGridColumnApi(params.columnApi);
	};

	const [userGridApi, setUserGridApi] = useState(null) as any;
	const [userGridColumnApi, setUserGridColumnApi] = useState(null);
	const onUserGridReady = (params: any) => {
		setUserGridApi(params.api);
		setUserGridColumnApi(params.columnApi);
	};

	let navigate = useNavigate();

	const setPageDataProperty = async (key: string, value: any, remove: boolean = false) => {
		let staleData = Object.assign({}, pageData);

		if (Array.isArray(staleData[key])) {
			// Push and Pop items from the array
			if (!remove) {
				staleData[key].push(value);
			} else {
				// This assumes that both the value provided and the objects in the array have id fields
				// Find the object matching our id in the array.
				let re = staleData[key].find((e: any) => e.id == value.id);
				if (re != null) {
					// Object found, now remove it.
					const index = staleData[key].indexOf(re, 0);
					if (index > -1) {
						staleData[key].splice(index, 1);
					}
				}
			}
		} else {
			// Just update the value
			staleData[key] = value;
		}
		setPageData(staleData);
	};

	const getPageData = async () => {
		setLoading(true);

		// Ensure we don't pass through organisationId if it's undefined or null
		let response = await appContext.http.request(
			"GET",
			`/api/admin/organisations/detail/` + (urlParams.organisationId != null ? urlParams.organisationId : ""),
			null,
			true,
			true
		);

		setLoading(false);
		if (response.success && response.data && response.data.success) {
			setPageData(response.data);
		}
	};

	const saveOrganisation = async () => {
		// Validation logic

		// Build and post the request
		setActionPending(true);

		let result = await appContext.http.request("POST", "/api/admin/organisations/save", pageData, true, true);
		setActionPending(false);
		if (result.success) {
			toast.success("Changes saved successfully.");
			if (pageMode == "CREATE") {
				navigate(`/admin/home/${result.data.authScopeId}`);
			}
		}
	};

	useEffect(() => {
		if (urlParams.organisationId && urlParams.organisationId != "") {
			setPageMode("EDIT");
		}

		getPageData();
	}, [appContext, urlParams.organisationId]);

	return (
		<>
			<MainCard>
				{loading == false && pageData && (
					<FlexHeader>
						<Breadcrumb breadcrumbs={pageData.breadcrumbs} />
						{urlParams.organisationId ? (
							<>
								<TitleH1NoBase>{pageData.name}</TitleH1NoBase>
							</>
						) : (
							<TitleH1NoBase>Create Organisation</TitleH1NoBase>
						)}
					</FlexHeader>
				)}
				<div>
					{loading == true && <Loading key="loading" />}
					{loading == false && (
						<div>
							<FormControl label={() => "Name"}>
								<Input
									value={pageData.name}
									onChange={(e) => {
										setPageDataProperty("name", e.currentTarget.value);
									}}
								/>
							</FormControl>

							<FormControl label={() => "Identifier"}>
								<Input
									value={pageData.slug}
									onChange={(e) => {
										setPageDataProperty("slug", e.currentTarget.value);
									}}
								/>
							</FormControl>

							<FormControl label={() => "Active"}>
								<Checkbox
									checked={pageData.active}
									onChange={() => {
										setPageDataProperty("active", !pageData.active);
									}}
								/>
							</FormControl>
							<Actions saveAction={saveOrganisation} saveActionPending={actionPending}></Actions>
						</div>
					)}
				</div>
			</MainCard>
		</>
	);
};
export default Page;
