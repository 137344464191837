const config = {
	API_URL: "https://bda-api.codefoundry.nz",
	LOG_REQUESTS: false,
	AUTH: {},
	ENVIRONMENT: "Beta",
	BRANDING: "BDA",
	BRANDING_LOGO_SRC: "/assets/bda_logo.png",
	APP_VERSION: "1.1.3",
	OFFLINE: false,
};

export default config;
