import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AppContext from "../../../helpers/AppContext";
import {
	FlexBody,
	FlexHeader,
	ThreeColField,
	ThreeColFieldInline,
	TwoColField,
} from "../../../helpers/BaseLayoutStyles";
import Loading from "../../../components/Loading";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Checkbox } from "baseui/checkbox";
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, SIZE } from "baseui/modal";
import Actions from "../../../components/Actions";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import EditableCheckbox from "../../../components/grid/EditableCheckbox";
import GridCheckbox from "../../../components/grid/Checkbox";
import { Select } from "baseui/select";
import { Textarea } from "baseui/textarea";
import { Tab, Tabs } from "baseui/tabs-motion";

const ResultDetail = (props: any) => {
	const params = useParams() as any;
	const appContext = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [actionPending, setActionPending] = useState(false);
	const [pageData, setPageData] = useState([] as any);
	const [resultCalculationTypeList, setResultCalculationTypeList] = useState([] as any);
	const [parentResultList, setParentResultList] = useState([] as any);
	const [activeKey, setActiveKey] = React.useState("0");

	const [runsGridApi, setRunsGridApi] = useState(null) as any;
	const [runsGridColumnApi, setRunsGridColumnApi] = useState(null) as any;
	const onRunsGridReady = (params: any) => {
		setRunsGridApi(params.api);
		setRunsGridColumnApi(params.columnApi);
		setRunsGridLayout(false);
	};

	const setRunsGridLayout = (print: boolean) => {
		if (print == null) {
			print = false;
		}
		if (runsGridApi != null) {
			if (print == true) {
				runsGridApi.setDomLayout("print");
			} else {
				runsGridApi.setDomLayout(null);
			}

			// Resize grid
			const allColumnIds = [] as any;
			if (runsGridColumnApi != null) {
				let cols = runsGridColumnApi.getAllColumns();
				if (cols != null) {
					cols.forEach((column: any) => {
						allColumnIds.push(column.colId);
					});
					runsGridColumnApi.autoSizeColumns(allColumnIds, false);
				}
			}
		}
	};

	const addRunRow = () => {
		let staleData = Object.assign({}, pageData);
		pageData.runs.push({ active: true });
		setPageData(staleData);
		runsGridApi.setRowData(pageData.runs);
	};

	let navigate = useNavigate();

	const setPageDataProperty = async (key: string, value: any, isArray: boolean = false, remove: boolean = false) => {
		let staleData = Object.assign({}, pageData);

		if (isArray == true) {
			// Push and Pop items from the array
			if (!remove) {
				staleData[key].push(value);
			} else {
				// This assumes that both the value provided and the objects in the array have id fields
				// Find the object matching our id in the array.
				let re = staleData[key].find((e: any) => e.id == value.id);
				if (re != null) {
					// Object found, now remove it.
					const index = staleData[key].indexOf(re, 0);
					if (index > -1) {
						staleData[key].splice(index, 1);
					}
				}
			}
		} else {
			// Just update the value
			if (Array.isArray(value)) {
				value = value[0];
			}
			staleData[key] = value;
		}
		setPageData(staleData);
	};

	const getPageData = async () => {
		setLoading(true);

		// Get Page Data
		let response = await appContext.http.request(
			"GET",
			`/api/admin/results/detail/${props.resultId}`,
			null,
			true,
			true
		);

		if (response.success && response.data && response.data.success) {
			setPageData(response.data);
			setRunsGridLayout(false);
		}

		// Get Lookup options
		let lupResponse = await appContext.http.request(
			"GET",
			`/api/admin/results/detaillookups/${props.eventId}`,
			null,
			true,
			true
		);
		if (lupResponse.success && lupResponse.data && lupResponse.data.success) {
			setResultCalculationTypeList(lupResponse.data.resultCalculationTypes.items);
			setParentResultList(lupResponse.data.parentResults.items);
		}

		setLoading(false);
	};

	const saveResult = async () => {
		// Validation logic

		// Build and post the request
		setActionPending(true);

		let result = await appContext.http.request("POST", "/api/admin/results/save", pageData, true, true);
		setActionPending(false);
		if (result.success) {
			toast.success("Changes saved successfully.");
		}
	};

	function close() {
		props.onClose();
	}

	useEffect(() => {
		getPageData();
	}, [appContext, props]);

	return (
		<>
			<FlexBody>
				{loading == true && <Loading key="loading" />}
				{loading == false && (
					<div>
						<TwoColField>
							<div>
								{resultCalculationTypeList && (
									<FormControl label={() => "Result Calculation Type"}>
										<Select
											value={pageData.resultCalculationType}
											onChange={(params) => setPageDataProperty("resultCalculationType", params.value)}
											options={resultCalculationTypeList}
											placeholder=""
										/>
									</FormControl>
								)}

								{/* {parentResultList && (
									<FormControl label={() => "Parent Result"}>
										<Select
											value={pageData.parentResult}
											onChange={(params) => setPageDataProperty("parentResult", params.value)}
											options={parentResultList}
											placeholder=""
											clearable={true}
											deleteRemoves={true}
										/>
									</FormControl>
								)} */}

								<ThreeColFieldInline>
									<div>
										<FormControl label={() => "Default Time Penalty"}>
											<Input
												type="number"
												value={pageData.defaultTimePenalty}
												onChange={(e) => {
													setPageDataProperty("defaultTimePenalty", e.currentTarget.value);
												}}
											/>
										</FormControl>
									</div>
									<div>
										<FormControl label={() => "Cone Penalty"}>
											<Input
												type="number"
												value={pageData.conePenalty}
												onChange={(e) => {
													setPageDataProperty("conePenalty", e.currentTarget.value);
												}}
											/>
										</FormControl>
									</div>
									<div>
										<FormControl label={() => "Jump Start Penalty"}>
											<Input
												type="number"
												value={pageData.jumpStartPenalty}
												onChange={(e) => {
													setPageDataProperty("jumpStartPenalty", e.currentTarget.value);
												}}
											/>
										</FormControl>
									</div>
								</ThreeColFieldInline>

								<div>
									<FormControl label={() => "Results are Final"}>
										<Checkbox
											checked={pageData.resultsFinalised}
											onChange={() => {
												setPageDataProperty("resultsFinalised", !pageData.resultsFinalised);
											}}
										/>
									</FormControl>
								</div>
							</div>

							<div>
								<div className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
									<AgGridReact
										rowData={pageData.runs}
										frameworkComponents={{
											navCheckboxRenderer: GridCheckbox,
											editCheckboxRenderer: EditableCheckbox,
										}}
										defaultColDef={{
											flex: 1,
											minWidth: 100,
											editable: true,
											resizable: true,
											sortable: true,
											filter: true,
										}}
										onGridReady={onRunsGridReady}
										onFirstDataRendered={() => setRunsGridLayout(false)}
									>
										<AgGridColumn field="number" headerName="No" />
										<AgGridColumn field="name" />
										<AgGridColumn field="eliminationCutoff" />
										<AgGridColumn field="active" cellRenderer="editCheckboxRenderer" />
									</AgGridReact>
								</div>
							</div>
						</TwoColField>
						<Actions
							addAction={addRunRow}
							addTitle="Run"
							saveAction={saveResult}
							saveActionPending={actionPending}
						></Actions>
					</div>
				)}
			</FlexBody>
		</>
	);
};
export default ResultDetail;
