import React, { useContext, useEffect, useMemo, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE } from "baseui/modal";
import { Select } from "baseui/select";
import { toast } from "react-toastify";
import { SHAPE } from "baseui/button";
import { FileUploader } from "baseui/file-uploader";
import AppContext from "../helpers/AppContext";

const ModalFileUpload = (props: any) => {
	const appContext = useContext(AppContext);
	const [isUploading, setIsUploading] = React.useState(false);
	const [parentId, setParentId] = useState(null) as any;
	const timeoutId = React.useRef<any>();

	function reset() {
		setIsUploading(false);
		clearTimeout(timeoutId.current);
	}

	// startProgress is only illustrative. Use the progress info returned
	// from your upload endpoint. This example shows how the file-uploader operates
	// if there is no progress info available.
	function startProgress() {
		setIsUploading(true);
		timeoutId.current = setTimeout(reset, 4000);
	}

	function close() {
		props.setIsOpen(false);
		props.onClose();
	}

	const uploadFile = async (file: File[]) => {
		const formData = new FormData();
		formData.append("body", file[0]);

		if (parentId != null) {
			console.log("Appending parentId");
			formData.append("parentId", parentId);
		} else {
			console.log("!!! No ParentID");
		}

		let result = await appContext.http.formRequest(
			"POST",
			"/api/admin/competitors/uploadcompetitors",
			formData,
			true,
			true
		);

		if (result.success) {
			toast.success("Changes saved successfully.");
			// Close the popup
			close();
		}
	};

	// const [value, setValue] = useState([] as any);

	useEffect(() => {
		// Initialise the lookup without any value
		setParentId(props.parentId);
	}, [props]);

	return (
		<>
			<Modal onClose={close} isOpen={props.isOpen}>
				<ModalHeader>{props.title}</ModalHeader>
				<ModalBody>
					<FileUploader
						onCancel={reset}
						onDrop={(acceptedFiles, rejectedFiles) => {
							// handle file upload...
							console.log(acceptedFiles, rejectedFiles);
							startProgress();
							uploadFile(acceptedFiles);
						}}
						progressMessage={isUploading ? `Uploading...` : ""}
						multiple={false}
						maxSize={1024000}
					/>
					{/* <Select
						value={value}
						// onChange={setValue}
						onChange={(params) => setValue(params.value)}
						options={props.options}
						// size={SIZE.default}
					/> */}
				</ModalBody>
				<ModalFooter>
					<ModalButton shape={SHAPE.pill} kind="secondary" onClick={close}>
						Cancel
					</ModalButton>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default ModalFileUpload;
