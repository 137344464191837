//import { Auth } from "aws-amplify";
import config from "./config";
import { toast } from "react-toastify";
import { ObjectFlags } from "typescript";

const http = (appContext: any) => {
	const endpointUrl = (url: string) => {
		return `${config.API_URL}${url}`;
	};

	const logRequest = (method: string, url: string, headers: any, body: any) => {
		console.log("\n");
		console.log(`${method}: ${endpointUrl(url)}`);

		for (const [key, value] of Object.entries(headers)) {
			console.log(`${key}: ${value}`);
		}
		console.log("\n");
		if (body) {
			console.log(body);
		}

		console.log("----------------------------------------");
	};

	function logResponse(
		statusCode: number,
		url: string,
		headers: any,
		body: any
	) {
		console.log("\n");
		console.log(`${statusCode.toString()}: ${endpointUrl(url)}`);

		for (const [key, value] of Object.entries(headers)) {
			console.log(`${key}: ${value}`);
		}
		console.log("\n");
		if (body) {
			console.log(body);
		}

		console.log("----------------------------------------");
	}

	const request = async (
		method: string,
		url: string,
		data: any,
		withAuth = true,
		handleErrors = true
	) => {
		let authHeader = {};
		let headers = {};
		let body = undefined;
		if (withAuth) {
			authHeader = {
				Authorization: `Bearer ${appContext.auth.authToken}`,
			};
		}

		// Normal GET/POST from React, where we are passing json.
		headers = {
			Accept: "application/json",
			"Content-Type": "application/json; charset=utf-8",
			...authHeader,
		};
		if (data) {
			body = JSON.stringify(data);
		}
		
		if (config.LOG_REQUESTS) {
			logRequest(method, url, headers, body);
		}
		
		//const res = await fetch(url, {
		const res = await fetch(endpointUrl(url), {
			headers: headers,
			method: method,
			body: body,
			credentials: "include",
		});

		if (res.ok) {
			const text = await res.text();
			const obj = JSON.parse(text);		

			if (config.LOG_REQUESTS) {
				logResponse(res.status, url, res.headers, text);
			}
			return { success: true, data: obj };
		}
		else {
			if (res.status === 401 || res.status === 403) {
				// Auth error. Always handle these (override page-level handling)
				handleError("You do not have permission to perform that action");
			}
			else {
				if (handleErrors && res.status >= 500) {
					// We want to raise an error internally. 
					// 500 Error will have no useful information (probably)
					handleError(`Unable to retrieve data (${res.status})`);
					return { success: false };
				}
				else {
					// Parse the response to see if it's structured with any message we can use
					// Assumes the response has Success and Message elements
					const text = await res.text();

					const obj = objToJson(text);
					//const obj = JSON.parse(text);	
					
					if (handleErrors) {
						// We want to raise an error internally.
						if (obj != null && obj.success != null && obj.success == false && obj.message != null)
						{
							handleError(obj.message);
						}
						else {
							handleError(`Unable to retrieve data (${res.status} - ${res.statusText})`);
						}							
						return { success: false };
					}
					else {
						// The calling logic will handle errors. Return the object in case it has more info.
						return { success: false, res: res, data: obj };
					}
				}
			}
		}
		
		// Default return. In theory we should never get here.
		return { success: false };
	};

	const objToJson = (item: any) => {
		item = typeof item !== "string"
		? JSON.stringify(item)
		: item;
	
		try {
			return JSON.parse(item);
		} catch (e) {
			return {
				success: false,
				data: item
			};
		}
	};

	const downloadRequest = async (
		method: string,
		url: string,
		data: any,
		withAuth = true,
		handleErrors = true
	) => {
		let authHeader = {};
		let headers = {};
		let body = undefined;
		if (withAuth) {
			authHeader = {
				Authorization: `Bearer ${appContext.auth.authToken}`,
			};
		}

		// Normal GET/POST from React, where we are passing json.
		headers = {
			Accept: "application/json",
			"Content-Type": "application/json; charset=utf-8",
			...authHeader,
		};
		// if (data) {
		// 	body = JSON.stringify(data);
		// }
		
		// if (config.LOG_REQUESTS) {
		// 	logRequest(method, url, headers, body);
		// }
		
		//const res = await fetch(url, {
		const res = await fetch(endpointUrl(url), {
			headers: headers,
			method: method,
			body: body,
			credentials: "include",
		});

		if (res.ok) {
			var obj = await res.blob();
			
			// Get the FileName from the headers
			var filename = "Downloaded File.pdf"
			// const header = res.headers.get('content-disposition');
			// if (header != null) {
			// 	const parts = header!.split(';');
			// 	filename = parts[1].split('=')[1].replaceAll('"', '');
			// }
			const header = res.headers.get('bda-file-name');
			if (header != null) {
				filename = header;
			}

			// Download the file in the browser. We're effectively simulating clicking a link that retrieves the file
			const objectUrl = URL.createObjectURL(obj);
			const link = document.createElement('a');
			link.href = objectUrl;
			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();
			
			return { success: true, data: obj };
		}
		else {
			if (res.status === 401 || res.status === 403) {
				// Auth error. Always handle these (override page-level handling)
				handleError("You do not have permission to perform that action");
			}
			else {
				if (handleErrors && res.status >= 500) {
					// We want to raise an error internally. 
					// 500 Error will have no useful information (probably)
					handleError(`Unable to retrieve file (${res.status})`);
					return { success: false };
				}
				else {
					// Parse the response to see if it's structured with any message we can use
					// Assumes the response has Success and Message elements
					
					if (handleErrors) {
						// We want to raise an error internally.
						
						handleError(`Unable to retrieve file (${res.status} - ${res.statusText})`);
						return { success: false };
					}
					else {
						// The calling logic will handle errors. Return the object in case it has more info.
						return { success: false, res: res};
					}
				}
			}
		}
		
		// Default return. In theory we should never get here.
		return { success: false };
	};

	const formRequest = async (
		method: string,
		url: string,
		data: any,
		withAuth = true,
		handleErrors = true
	) => {
		let authHeader = {};
		let headers = {};
		let body = undefined;
		if (withAuth) {
			authHeader = {
				Authorization: `Bearer ${appContext.auth.authToken}`,
			};
		}

		// Posting a form object. Use this for file uploads.
		headers = {
			//"Content-Type": "multipart/form-data",
			...authHeader
		};
		if (data) {
			body = data;
		}
	

		if (config.LOG_REQUESTS) {
			logRequest(method, url, headers, body);
		}
		
		//const res = await fetch(url, {
		const res = await fetch(endpointUrl(url), {
			headers: headers,
			method: method,
			body: body,
			credentials: "include",
		});

		if (res.ok) {
			const text = await res.text();

			if (config.LOG_REQUESTS) {
				logResponse(res.status, url, res.headers, text);
			}
			const obj = JSON.parse(text);		

			return { success: true, data: obj };
		}
		else {
			if (res.status === 401 || res.status === 403) {
				// Auth error. Always handle these (override page-level handling)
				handleError("You do not have permission to perform that action");
			}
			else if (handleErrors) {
				// We want to raise an error internally. 
				if (res.status >= 500) {
					// 500 Error will have no useful information (probably)
					handleError(`Unable to retrieve data (${res.status})`);
				}
				else {
					// Parse the response to see if it's structured with any message we can use
					// Assumes the response has Success and Message elements
					const text = await res.text();
					const obj = JSON.parse(text);		
					if (obj != null && obj.success != null && obj.success == false && obj.message != null)
					{
						handleError(obj.message);
					}
					else {
						handleError(`Unable to retrieve data (${res.status} - ${res.statusText})`);
					}
				}
				return { success: false };
			}
			else {
				// The calling logic will handle errors. Return the object in case it has more info.
				return { success: false, res: res };
			}

		}
		
		// Default return. In theory we should never get here.
		return { success: false };
	};

	const handleError = (message: string) => {
		toast.error(message);
	};

	return {
		request, formRequest, downloadRequest
	};
};

export default http;
