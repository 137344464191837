/*
	Nav.tsx
	Currently this is a hamburger. 
	We also want a version for a dashboard-style layout with a list of items pre-expanded on the side.
*/

import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import AppContext from "../helpers/AppContext";
import { ControlRow, TwoColField } from "../helpers/BaseLayoutStyles";
import { Button, SHAPE, SIZE } from "baseui/button";
import { Card, StyledBody } from "baseui/card";
import { useNavigate } from "react-router-dom";
import config from "../utils/config";
import { Modal, ModalBody, ModalHeader } from "baseui/modal";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";

const NavMenu = styled.ul`
	list-style-type: none;
	padding: 0;
	margin: 0;
`;

const NavItem = styled.li`
	margin: 0;
	padding: 1rem 0;
	border-bottom: 1px solid #f5f5f5;
	cursor: pointer;
	font-weight: 500;
`;

const NavSpacer = styled.li`
	margin: 0;
	padding: 0;
	margin-right: 0.5rem;
	height: 0px;
	border-top: 1px solid #6a6c6e;
`;

const NavGrid = styled.div`
	display: grid;
	grid-template-rows: auto min-content min-content;
	height: 100%;
`;

const UserGrid = styled.div`
	display: flex;
	align-items: center;
	> div {
		margin-right: 1rem;
	}
`;

const Spacer = styled.div`
	margin: 0;
	padding: 0;
	padding-top: 0.25rem;
	height: 0;
	border-bottom: 1px solid #6a6c6e;
`;

const BrandingCard = styled.div`
	margin-top: 0.5rem;
	font-size: 0.75rem;
`;

const ActionArea = styled.div`
	margin-top: 1rem;
`;

const TwoColFlex = styled.div`
	flex: 1 1 auto;
	display: grid;
	grid-template-columns: auto auto;
	grid-gap: 1rem;
`;

const NavContent = (props: any) => {
	const [signingIn, setSigningIn] = useState(false);
	const [signingOut, setSigningOut] = useState(false);
	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const appContext = useContext(AppContext);

	const navigate = useNavigate();

	const staticMenuItems: any = {
		items: [
			{
				name: "Current Events",
				slug: "/",
			},
			{
				name: "Result Search",
				slug: "/search",
			},

			{
				name: "About",
				slug: "/about",
			},
		],
	};

	useEffect(() => {
		if (appContext.state.auth.authenticated === true) {
			setSigningIn(false);
		}
	}, [appContext]);

	return (
		<>
			<NavGrid>
				<>
					<NavMenu>
						{staticMenuItems.items.map((item: any) => {
							return (
								<NavItem
									onClick={() => {
										navigate(`${item.slug}`);

										props.setMenuIsOpen(false);
									}}
									key={`/${item.slug}`}
								>
									{item.name}
								</NavItem>
							);
						})}

						{!props.loading && props.user && props.user.pages.length > 0 && (
							<>
								<NavSpacer />
								{props.user.pages.map((item: any) => {
									return (
										<NavItem
											onClick={() => {
												navigate(`${item.slug}`);

												props.setMenuIsOpen(false);
											}}
											key={`/${item.slug}`}
										>
											{item.name}
										</NavItem>
									);
								})}
							</>
						)}
					</NavMenu>
					{appContext.state.auth.authenticated == true && (
						<Card>
							<StyledBody>
								<UserGrid>
									<div>
										<strong>{props.user.firstName + " " + props.user.lastName}</strong>
									</div>
								</UserGrid>
							</StyledBody>
						</Card>
					)}
					<ActionArea>
						{appContext.state.auth.authenticated == true ? (
							<Button
								onClick={async () => {
									setSigningOut(true);
									appContext.mutate.logout();
									setSigningOut(false);
									props.setMenuIsOpen(false);
									navigate("/");
								}}
								isLoading={signingOut}
								size={SIZE.compact}
								shape={SHAPE.pill}
							>
								Log Out
							</Button>
						) : (
							<Button
								onClick={async () => {
									setSigningIn(true);
								}}
								isLoading={signingIn}
								size={SIZE.compact}
								shape={SHAPE.pill}
							>
								Log In
							</Button>
						)}
						<TwoColFlex>
							<BrandingCard>CodeFoundry © 2021-{new Date().getFullYear()}</BrandingCard>
							<BrandingCard>Version {config.APP_VERSION}</BrandingCard>
						</TwoColFlex>
						<Spacer />
						<BrandingCard>
							<a href="https://www.codefoundry.nz">
								<img src="/assets/fabrica-cf.png" alt="Fabricated by CodeFoundry" style={{ height: "20px" }} />
							</a>
						</BrandingCard>
					</ActionArea>
				</>
			</NavGrid>

			<Modal onClose={() => setSigningIn(false)} isOpen={signingIn}>
				<ModalHeader>Log In</ModalHeader>
				<ModalBody>
					<div>
						<FormControl label={() => "Username *"}>
							<Input onChange={(e) => setUserName(e.currentTarget.value)} placeholder="Enter your username" />
						</FormControl>
						<FormControl label={() => "Password *"}>
							<Input
								onChange={(e) => setPassword(e.currentTarget.value)}
								type="password"
								placeholder="Enter your password"
							/>
						</FormControl>

						<ControlRow>
							<Button
								isLoading={appContext.state.auth.isAuthPending}
								onClick={() => {
									appContext.mutate.login(userName, password);
								}}
								size={SIZE.compact}
								shape={SHAPE.pill}
							>
								Log In
							</Button>

							<Button
								kind="secondary"
								onClick={() => {
									setSigningIn(false);
								}}
								size={SIZE.compact}
								shape={SHAPE.pill}
							>
								Cancel
							</Button>
						</ControlRow>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};
export default NavContent;
