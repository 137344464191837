// AppContext is a global state object
import React from "react";

const AppContextValue = {
	state: {
		auth: {
			authenticated: false,
			authToken: "",
			isAuthPending: false,
			cookieLoginAttempted: false,
		},
		userPreferences: {
			isloggedIn: false,
			fullName: "",
			firstName: "",
			lastName: "",
			email: "",
			pages: [],
			roles: [],
		},
		pageTitlePrefix: "LightCore - ",
	},
	mutate: {} as any,
	http: {} as any,
};

export { AppContextValue };

export default React.createContext(AppContextValue);
