import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import AppContext from "../../../helpers/AppContext";
import {
	FlexBody,
	FlexHeader,
	MainCard,
	NavigationLink,
	TitleH1,
	TwoColField,
	TwoColFieldInline,
	TwoColRightGutter,
} from "../../../helpers/BaseLayoutStyles";
import Loading from "../../../components/Loading";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Checkbox } from "baseui/checkbox";
import ModalFileUpload from "../../../components/ModalFileUpload";
import Actions from "../../../components/Actions";
import Breadcrumb from "../../../components/Breadcrumb";
import { DatePicker } from "baseui/datepicker";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import NavButton from "../../../components/grid/NavButton";
import ModalButton from "../../../components/grid/ModalButton";
import DocumentationButton from "../../../components/grid/DocumentationButton";
import DeleteButton from "../../../components/grid/DeleteButton";
import GridCheckbox from "../../../components/grid/Checkbox";
import { Tab, Tabs } from "baseui/tabs-motion";
import { Select } from "baseui/select";
import CompetitorDetailModal from "./CompetitorDetailModal";
import slugify from "../../../utils/slugify";
import dayjs from "dayjs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faKeyboard, faUpload } from "@fortawesome/free-solid-svg-icons";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";

import ModalImageUpload from "../../../components/ModalImageUpload";
import config from "../../../utils/config";
import ResultsDashboard from "../../timing/ResultsDashboard";
import TimingDashboard from "../../timing/TimingDashboard";
import ResultDetail from "./ResultDetail";
import { InputOverrides } from "../../../helpers/BasewebOverrides";

const QuickAction = styled.div`
	padding-bottom: 0.75rem;
`;

const Page = () => {
	const urlParams = useParams() as any;
	const appContext = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [actionPending, setActionPending] = useState(false);
	const [pageData, setPageData] = useState([] as any);
	const [pageMode, setPageMode] = useState("CREATE");
	const [eventTypeList, setEventTypeList] = useState([] as any);
	const [activeKey, setActiveKey] = React.useState("0");
	const [eventId, setEventId] = useState(null as any);

	const [showCompetitorModal, setShowCompetitorModal] = React.useState(false);
	const [currentCompetitorId, setCurrentCompetitorId] = React.useState(null as any);

	const [showResultModal, setShowResultModal] = React.useState(false);
	const [currentResultId, setCurrentResultId] = React.useState(null as any);

	const [uploadModalOpen, setUploadModalOpen] = useState(false);
	const [logoUploadModalOpen, setLogoUploadModalOpen] = useState(false);

	// Grid Configuration
	const [classGridApi, setClassGridApi] = useState(null) as any;
	const [classGridColumnApi, setClassGridColumnApi] = useState(null) as any;
	const onClassGridReady = (params: any) => {
		setClassGridApi(params.api);
		setClassGridColumnApi(params.columnApi);
		setClassGridLayout(false);
	};

	const setClassGridLayout = (print: boolean) => {
		if (print == null) {
			print = false;
		}
		if (classGridApi != null) {
			if (print == true) {
				classGridApi.setDomLayout("print");
			} else {
				classGridApi.setDomLayout(null);
			}

			// Resize grid
			const allColumnIds = [] as any;
			if (classGridColumnApi != null) {
				let cols = classGridColumnApi.getAllColumns();
				if (cols != null) {
					cols.forEach((column: any) => {
						allColumnIds.push(column.colId);
					});
					classGridColumnApi.autoSizeColumns(allColumnIds, false);
				}
			}
		}
	};

	const [competitorGridApi, setCompetitorGridApi] = useState(null) as any;
	const [competitorGridColumnApi, setCompetitorGridColumnApi] = useState(null) as any;
	const onCompetitorGridReady = (params: any) => {
		setCompetitorGridApi(params.api);
		setCompetitorGridColumnApi(params.columnApi);
		setCompetitorGridLayout(false);
	};

	const setCompetitorGridLayout = (print: boolean) => {
		if (print == null) {
			print = false;
		}
		if (competitorGridApi != null) {
			if (print == true) {
				competitorGridApi.setDomLayout("print");
			} else {
				competitorGridApi.setDomLayout(null);
			}

			// Resize grid
			const allColumnIds = [] as any;
			if (competitorGridColumnApi != null) {
				let cols = competitorGridColumnApi.getAllColumns();
				if (cols != null) {
					cols.forEach((column: any) => {
						allColumnIds.push(column.colId);
					});
					competitorGridColumnApi.autoSizeColumns(allColumnIds, false);
				}
			}
		}
	};

	// const [resultGridApi, setResultGridApi] = useState(null) as any;
	// const [resultGridColumnApi, setResultGridColumnApi] = useState(null) as any;
	// const onResultGridReady = (params: any) => {
	// 	setResultGridApi(params.api);
	// 	setResultGridColumnApi(params.columnApi);
	// 	setResultGridLayout(false);
	// };

	// const setResultGridLayout = (print: boolean) => {
	// 	if (print == null) {
	// 		print = false;
	// 	}
	// 	if (resultGridApi != null) {
	// 		if (print == true) {
	// 			resultGridApi.setDomLayout("print");
	// 		} else {
	// 			resultGridApi.setDomLayout(null);
	// 		}

	// 		// Resize grid
	// 		const allColumnIds = [] as any;
	// 		if (resultGridColumnApi != null) {
	// 			let cols = resultGridColumnApi.getAllColumns();
	// 			if (cols != null) {
	// 				cols.forEach((column: any) => {
	// 					allColumnIds.push(column.colId);
	// 				});
	// 				resultGridColumnApi.autoSizeColumns(allColumnIds, false);
	// 			}
	// 		}
	// 	}
	// };

	const addClassRow = () => {
		let staleData = Object.assign({}, pageData);
		pageData.classes.push({ active: true });
		setPageData(staleData);
		classGridApi.setRowData(pageData.classes);
	};

	// Modal Popups
	const closeCompetitorModals = () => {
		setCurrentCompetitorId(null);
		setShowCompetitorModal(false);
		setUploadModalOpen(false);
		setLogoUploadModalOpen(false);
		getPageData();
	};

	const closeResultModals = () => {
		setCurrentResultId(null);
		setShowResultModal(false);
		getPageData();
	};

	let navigate = useNavigate();

	const setPageDataProperty = async (key: string, value: any, isArray: boolean = false, remove: boolean = false) => {
		let staleData = Object.assign({}, pageData);

		if (isArray == true) {
			// Push and Pop items from the array
			if (!remove) {
				staleData[key].push(value);
			} else {
				// This assumes that both the value provided and the objects in the array have id fields
				// Find the object matching our id in the array.
				let re = staleData[key].find((e: any) => e.id == value.id);
				if (re != null) {
					// Object found, now remove it.
					const index = staleData[key].indexOf(re, 0);
					if (index > -1) {
						staleData[key].splice(index, 1);
					}
				}
			}
		} else {
			// Just update the value
			if (Array.isArray(value)) {
				value = value[0];
			}
			staleData[key] = value;
		}

		if (key == "name" || key == "eventDate") {
			const slug = slugify(
				`${staleData.eventDate != null ? dayjs(staleData.eventDate).format("YYYY-MM-DD") + "-" : ""}-${
					staleData.organisation
				}-${staleData.name}`
			);
			staleData["slug"] = slug;
		}
		setPageData(staleData);
	};

	const getPageData = async () => {
		setLoading(true);
		let mode = eventId ? "EDIT" : "CREATE";
		setPageMode(mode);

		// Ensure we don't pass through eventId if it's undefined or null
		let response = await appContext.http.request(
			"GET",
			`/api/admin/events/detail/` + (eventId != null ? eventId : ""), //(urlParams.eventId != null ? urlParams.eventId : ""),
			null,
			true,
			true
		);

		if (response.success && response.data && response.data.success) {
			if (mode == "CREATE" && urlParams.organisationId && urlParams.organisationId != "") {
				response.data.organisationId = urlParams.organisationId;
				response.data.organisation = urlParams.organisation;
			}
			setPageData(response.data);
			setClassGridLayout(false);
			// setResultGridLayout(false);
			setCompetitorGridLayout(false);
		}

		let lupResponse = await appContext.http.request("GET", `/api/admin/events/eventtypelist`, null, true, true);
		if (lupResponse.success && lupResponse.data && lupResponse.data.success) {
			setEventTypeList(lupResponse.data.items);
		}

		setLoading(false);
	};

	const saveEvent = async () => {
		// Validation logic

		// Build and post the request
		setActionPending(true);

		let result = await appContext.http.request("POST", "/api/admin/events/save", pageData, true, true);
		setActionPending(false);
		if (result.success) {
			toast.success("Changes saved successfully.");
			if (eventId == null) {
				setEventId(result.data.id); // Page will refresh indirectly
			} else {
				getPageData(); // Refresh the page
			}
		}
	};

	const printEntryList = async () => {
		if (eventId != null) {
			let response = await appContext.http.downloadRequest(
				"GET",
				`/api/admin/events/print/entrylist/${eventId}`,
				null,
				true,
				true
			);
		} else {
			toast.error("Unable to print: no Event Id found.");
		}
	};

	const downloadTemplate = async () => {
		// Download the file in the browser. We're effectively simulating clicking a link that retrieves the file
		const link = document.createElement("a");
		link.href = `/assets/Seeded-Format.csv`;
		link.setAttribute("download", "Seeded-Format.csv");
		document.body.appendChild(link);
		link.click();
	};

	const printResult = async (resultId: string, sortBy: string) => {
		if (resultId != null) {
			let response = await appContext.http.downloadRequest(
				"GET",
				`/api/public/results/print/${resultId}/${sortBy}`,
				null,
				true,
				true
			);
		}
	};
	useEffect(() => {
		if (urlParams.eventId && urlParams.eventId != "") {
			setEventId(urlParams.eventId);
		} else {
			getPageData();
		}
	}, [appContext, urlParams]);

	useEffect(() => {
		getPageData();
	}, [eventId]);

	return (
		<>
			<MainCard>
				{loading == false && pageData && (
					<>
						<Breadcrumb breadcrumbs={pageData.breadcrumbs} />
						<FlexHeader>
							{eventId ? (
								<>
									<TitleH1>{pageData.name}</TitleH1>
								</>
							) : (
								<TitleH1>Create Event</TitleH1>
							)}{" "}
							<div className="responsive">
								{pageData && pageData.organisationLogoUrl && (
									<img
										src={`${config.API_URL}${pageData.organisationLogoUrl}`}
										alt="Logo"
										style={{ maxHeight: "40px" }}
									/>
								)}
							</div>
						</FlexHeader>
					</>
				)}
				<FlexBody>
					{loading == true && <Loading key="loading" />}
					{loading == false && (
						<div>
							<Tabs
								activeKey={activeKey}
								onChange={({ activeKey }) => {
									setActiveKey(activeKey.toString());
								}}
								activateOnFocus
							>
								<Tab title="Event Setup">
									<TwoColRightGutter>
										<div>
											<TwoColField>
												<div>
													<FormControl label={() => "Name"}>
														<Input
															value={pageData.name}
															onChange={(e) => {
																setPageDataProperty("name", e.currentTarget.value);
															}}
															overrides={InputOverrides}
														/>
													</FormControl>
													<TwoColFieldInline>
														<div>
															<FormControl label={() => "Event Date"}>
																<DatePicker
																	value={pageData.eventDate ? [new Date(pageData.eventDate)] : []}
																	onChange={({ date }) =>
																		setPageDataProperty("eventDate", Array.isArray(date) ? date : [date])
																	}
																	formatString="dd/MM/yyyy"
																	placeholder="dd/MM/yyyy"
																/>
															</FormControl>
														</div>
														<div>
															<FormControl label={() => "Event Type"}>
																<Select
																	value={pageData.eventType}
																	onChange={(params) => setPageDataProperty("eventType", params.value)}
																	options={eventTypeList}
																	placeholder=""
																/>
															</FormControl>
														</div>
													</TwoColFieldInline>

													<FormControl label={() => "Venue"}>
														<Input
															value={pageData.venue}
															onChange={(e) => {
																setPageDataProperty("venue", e.currentTarget.value);
															}}
															overrides={InputOverrides}
														/>
													</FormControl>
													{pageData && pageData.results && pageData.results.length == 1 && (
														<>
															<FormControl
																label={() => "Public Results Link"}
																caption="Copy this link when wanting to post a link to your results on external websites/Facebook etc"
															>
																<div>
																	{document.location.origin}/result/{pageData.results[0].id}
																</div>
															</FormControl>
															<br />
														</>
													)}
												</div>
												<div>
													{pageMode != "CREATE" && (
														<FormControl label={() => "Event Logo / Sponsor"}>
															<>
																<div>
																	{pageData && pageData.eventLogoUrl ? (
																		<img
																			src={`${config.API_URL}${pageData.eventLogoUrl}`}
																			alt="Logo"
																			style={{ maxHeight: "200px", maxWidth: "300px" }}
																		/>
																	) : (
																		<div
																			style={{
																				width: "200px",
																				height: "110px",
																				fontStyle: "italic",
																				display: "flex",
																				justifyContent: "center",
																				paddingTop: "80px",
																				verticalAlign: "middle",
																				border: "solid black 1px",
																			}}
																		>
																			No logo set
																		</div>
																	)}
																</div>
																<NavigationLink
																	onClick={() => {
																		setLogoUploadModalOpen(true);
																	}}
																>
																	Change Logo
																</NavigationLink>
																<ModalImageUpload
																	isOpen={logoUploadModalOpen}
																	onClose={closeCompetitorModals}
																	setIsOpen={setLogoUploadModalOpen}
																	title="Upload Event Logo"
																	onAdd={(value: any) => {
																		getPageData();
																	}}
																	api="/api/admin/events/uploadlogo"
																	parentId={pageData.id}
																></ModalImageUpload>
															</>
														</FormControl>
													)}
												</div>
											</TwoColField>
										</div>

										{eventId && (
											<div>
												<h4>Quick Actions</h4>
												<QuickAction>
													<NavigationLink onClick={() => downloadTemplate()}>
														<FontAwesomeIcon icon={faDownload} style={{ paddingRight: ".35rem" }} /> Download
														Competitors Template
													</NavigationLink>
												</QuickAction>
												<QuickAction>
													<NavigationLink
														onClick={() => {
															setUploadModalOpen(true);
														}}
													>
														<FontAwesomeIcon icon={faUpload} style={{ paddingRight: ".35rem" }} /> Upload Competitors
														File
													</NavigationLink>
												</QuickAction>
												<QuickAction>
													<NavigationLink
														onClick={() => {
															printEntryList();
														}}
													>
														<FontAwesomeIcon icon={faFilePdf} style={{ paddingRight: ".55rem" }} /> Download Entry List
													</NavigationLink>
												</QuickAction>
												{pageData && pageData.results && pageData.results.length == 1 && (
													<>
														{/* <QuickAction>
															<NavigationLink
																onClick={() => {
																	navigate(`/timing/dashboard/${pageData.results[0].id}`);
																}}
															>
																<FontAwesomeIcon icon={faKeyboard} style={{ paddingRight: ".25rem" }} /> Enter Times
															</NavigationLink>
														</QuickAction> */}
														<QuickAction>
															<NavigationLink
																onClick={() => {
																	printResult(pageData.results[0].id, "Overall");
																}}
															>
																<FontAwesomeIcon icon={faFilePdf} style={{ paddingRight: ".55rem" }} /> Print Results
																(Overall)
															</NavigationLink>
														</QuickAction>
														<QuickAction>
															<NavigationLink
																onClick={() => {
																	printResult(pageData.results[0].id, "Class");
																}}
															>
																<FontAwesomeIcon icon={faFilePdf} style={{ paddingRight: ".55rem" }} /> Print Results
																(Class)
															</NavigationLink>
														</QuickAction>
													</>
												)}

												<br />
												<FormControl label={() => "Event is Cancelled"}>
													<Checkbox
														checked={!pageData.active}
														onChange={() => {
															setPageDataProperty("active", !pageData.active);
														}}
													/>
												</FormControl>
											</div>
										)}
									</TwoColRightGutter>
									<Actions saveAction={saveEvent} saveActionPending={actionPending}></Actions>
								</Tab>

								<Tab title={"Results Setup"} disabled={pageMode == "CREATE"}>
									{pageData && pageData.results && pageData.results.length > 0 && (
										<ResultDetail resultId={pageData.results[0].id} eventId={eventId} />
									)}
								</Tab>

								<Tab
									title={"Competitors" + (pageData.competitors && ` ( ${pageData.competitors.length} ) `)}
									disabled={pageMode == "CREATE"}
								>
									<div className="ag-theme-alpine" style={{ height: 460, width: "100%" }}>
										<AgGridReact
											rowData={pageData.competitors}
											frameworkComponents={{
												navButtonRenderer: NavButton,
												navCheckboxRenderer: GridCheckbox,
												functionButtonRenderer: ModalButton,
												documentationButtonRenderer: DocumentationButton,
											}}
											defaultColDef={{
												flex: 1,
												minWidth: 50,
												editable: true,
												resizable: true,
												sortable: true,
												filter: true,
											}}
											onGridReady={onCompetitorGridReady}
											onFirstDataRendered={() => setCompetitorGridLayout(false)}
											onRowClicked={(e: any) => {
												setCurrentCompetitorId(e.data.id);
												setShowCompetitorModal(true);
											}}
										>
											<AgGridColumn field="number" headerName="No" maxWidth={95} />
											<AgGridColumn field="name" minWidth={180} />
											<AgGridColumn field="vehicle" minWidth={130} />
											<AgGridColumn field="club" minWidth={100} maxWidth={100} />
											<AgGridColumn field="class" maxWidth={110} headerName="Class" />
											<AgGridColumn
												field="withdrawn"
												cellRenderer="navCheckboxRenderer"
												maxWidth={115}
												headerName="Withdrawn"
											/>
											<AgGridColumn
												field="id"
												editable={false}
												cellRenderer="functionButtonRenderer"
												headerName=""
												cellRendererParams={{
													text: "Details",
													setObjectId: setCurrentCompetitorId,
													setShowModal: setShowCompetitorModal,
												}}
												minWidth={105}
												maxWidth={105}
											/>
										</AgGridReact>
										<Actions
											addAction={() => {
												setCurrentCompetitorId(null);
												setShowCompetitorModal(true);
											}}
											addTitle="Competitor"
											uploadAction={() => setUploadModalOpen(true)}
											uploadTitle="Competitors"
											downloadTemplateAction={() => downloadTemplate()}
										></Actions>
									</div>
									{showCompetitorModal && (
										<CompetitorDetailModal
											isOpen={showCompetitorModal}
											onClose={closeCompetitorModals}
											competitorId={currentCompetitorId}
											eventId={pageData.id}
										/>
									)}
								</Tab>

								<Tab
									title={"Classes" + (pageData.classes && ` ( ${pageData.classes.length} ) `)}
									disabled={pageMode == "CREATE"}
								>
									<div className="ag-theme-alpine" style={{ height: 460, width: "100%" }}>
										<AgGridReact
											rowData={pageData.classes}
											frameworkComponents={{
												navButtonRenderer: NavButton,
												deleteButtonRenderer: DeleteButton,
											}}
											defaultColDef={{
												flex: 1,
												minWidth: 100,
												editable: true,
												resizable: true,
												sortable: true,
												filter: true,
											}}
											onGridReady={onClassGridReady}
											onFirstDataRendered={() => setClassGridLayout(false)}
										>
											<AgGridColumn field="code" />
											<AgGridColumn field="name" />

											<AgGridColumn field="id" editable={false} cellRenderer="deleteButtonRenderer" headerName="" />
										</AgGridReact>
										<Actions
											saveAction={saveEvent}
											saveActionPending={actionPending}
											addAction={addClassRow}
											addTitle="Class"
										></Actions>
									</div>
								</Tab>

								<Tab title={"Documents"} disabled={pageMode == "CREATE"}>
									<h4>Event Information</h4>
									<TwoColField>
										<NavigationLink>
											<FontAwesomeIcon icon={faFilePdf} /> Entry List
										</NavigationLink>
										<QuickAction>
											<NavigationLink
												onClick={() => {
													printEntryList();
												}}
											>
												<FontAwesomeIcon icon={faDownload} />
											</NavigationLink>
										</QuickAction>
									</TwoColField>

									{pageData &&
										pageData.results &&
										pageData.results.map((result: any, index: number) => {
											return (
												<>
													<TwoColField>
														<NavigationLink>
															<FontAwesomeIcon icon={faFilePdf} /> Results - {result.name} - (Overall)
														</NavigationLink>
														<QuickAction>
															<NavigationLink
																onClick={() => {
																	console.log(result);
																	printResult(result.id, "Overall");
																}}
															>
																<FontAwesomeIcon icon={faDownload} />
															</NavigationLink>
														</QuickAction>
													</TwoColField>
													<TwoColField>
														<NavigationLink>
															<FontAwesomeIcon icon={faFilePdf} /> Results - {result.name} - (Class)
														</NavigationLink>
														<QuickAction>
															<NavigationLink
																onClick={() => {
																	printResult(result.id, "Class");
																}}
															>
																<FontAwesomeIcon icon={faDownload} />
															</NavigationLink>
														</QuickAction>
													</TwoColField>
												</>
											);
										})}
								</Tab>

								<Tab title={"Enter Times"} disabled={pageMode == "CREATE"}>
									{pageData && pageData.results && pageData.results.length > 0 && (
										<TimingDashboard resultId={pageData.results[0].id} />
									)}
								</Tab>
								<Tab title={"View Results"} disabled={pageMode == "CREATE"}>
									{pageData && pageData.results && pageData.results.length > 0 && (
										<ResultsDashboard resultId={pageData.results[0].id} />
									)}
								</Tab>
							</Tabs>

							<ModalFileUpload
								isOpen={uploadModalOpen}
								onClose={closeCompetitorModals}
								setIsOpen={setUploadModalOpen}
								title="Upload Competitors"
								onAdd={(value: any) => {
									getPageData();
								}}
								parentId={pageData.id}
							/>
						</div>
					)}
				</FlexBody>
			</MainCard>
		</>
	);
};
export default Page;
