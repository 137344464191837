import { Button } from "baseui/button";
import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import AppContext from "../helpers/AppContext";
import { FlexBody, MainCard } from "../helpers/BaseLayoutStyles";

const Container = styled.div`
	display: grid;
	grid-template-rows: auto;
	justify-items: center;
	> img {
		width: 30%;
	}

	&.large {
		> img {
			width: 60%;
		}
	}
`;

const SignOutArea = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 2rem;
`;

const Unavailable = () => {
	const [signingOut, setSigningOut] = useState(false);
	const appContext = useContext(AppContext);
	const navigate = useNavigate();

	return (
		<MainCard>
			<FlexBody className="flexBody150">
				<h1>BDA Results is currently down for maintenance.</h1>
				<h3>Check back soon.</h3>
			</FlexBody>
		</MainCard>
	);
};
export default Unavailable;
