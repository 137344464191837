import React from "react";
import { Button, SHAPE, SIZE } from "baseui/button";
import { useNavigate } from "react-router-dom";

export default (props: any) => {
	let navigate = useNavigate();

	const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

	const navLink_OnClick = () => {
		if (props.url != null) {
			navigate(props.url + "/" + cellValue);
		}
	};

	return (
		<span>
			<a
				onClick={() => {
					navLink_OnClick();
				}}
			>
				{props.text}
			</a>
		</span>
	);
};
