import styled from "styled-components";

// Styled Objects

const Footer = styled.div`
	display: flex;
	height: 35px;
	padding-left: 1rem;
	padding-right: 1rem;
`;
const FooterBox = styled.div`
	display: block;
	overflow-y: none;
	width: 100%;
	padding-top: 5px;
`;

const LayoutPanel = styled.div`
	display: flex;
	height: 100vh;
	width: 100%;
	flex-direction: column;
	flex-flow: column;
	iframe {
		border: none !important;
	}

	.content-shadow {
		box-shadow: 0 10px 50px -2px rgba(0, 0, 0, 0.14);
	}
`;

const ContainerBase = styled.div``;

const ContainerPage = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

const TwoColField = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 3rem;
`;

const TwoColFieldInline = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 0.5rem;
`;

const ThreeColField = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 3rem;
`;

const ThreeColFieldInline = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 0.5rem;
`;

const TwoColRightGutter = styled.div`
	display: grid;
	grid-template-columns: 1fr 0.3fr;
	grid-gap: 4rem;
`;

const TwoColLeftGutter = styled.div`
	display: grid;
	grid-template-columns: 0.3fr 1fr;
	grid-gap: 3rem;
`;

const TitleH1NoBase = styled.h1`
	2em;
	margin-bottom: 0px;
	margin-top: 0px;
`;

const TitleH1 = styled.h1`
	2em;
	margin-top: 0px;
`;

const TitleH2NoBase = styled.h2`
	1.9em;
	margin-bottom: 0px;
	margin-top: 0px;
`;

const TitleH2 = styled.h2`
	1.9em;
	margin-top: 0px;
`;

const TitleH3NoBase = styled.h3`
	1.75em;
	margin-bottom: 0px;
	margin-top: 0px;
`;

const TitleH3 = styled.h3`
	1.75em;
	margin-top: 0px;
`;

const MainPanel = styled.div`
	display: flex;
	flex: 1 1 auto;
`;

const ContainerBox = styled.div`
	margin: 0.25rem;
	margin-top: 0;
	box-shadow: 0 10px 50px -2px rgba(0, 0, 0, 0.14);
	padding-top: 0.5rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	overflow-y: auto;
	width: 100%;
	margin-left: 0.5rem;
	margin-right: 0.5rem;

	flex: 1 1 auto;
`;

const FlexHeader = styled.div`
	flex: 1 1 auto;
	display: grid;
	grid-template-columns: auto auto;
	div {
		text-align: right;
	}
	margin: 1rem;
`;

const FlexHeaderFullWidth = styled.div`
	flex: 1 1 auto;
	display: grid;
`;

const FlexHeaderChildLeft = styled.div`
	text-align: left !important;
	div {
		text-align: left !important;
	}
`;

const FlexHeaderLeft = styled.div`
	display: grid;
	grid-template-columns: auto auto;
`;

const FlexBody = styled.div`
	flex: 1 1 auto;
	flex-grow: 1;
	display: content;
`;

const FlexFooter = styled.div`
	display: content;
	bottom: 0px;
	height: 30px;
`;

const StyledTableRow = styled.div`
	display: contents;
`;

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
`;

const FieldText = styled.div`
	font-size: 16px;
	padding-left: 15px;
	padding-right: 15px;
`;

const NavigationLink = styled.a`
	cursor: pointer;
`;

// Composite Objects
const MainCard = (props: any) => {
	return <ContainerBox>{props.children}</ContainerBox>;
};

const ControlRow = (props: any) => {
	return <Row>{props.children}</Row>;
};

const ActionRow = (props: any) => {
	return <FlexHeader>{props.children}</FlexHeader>;
};

const Hr = styled.hr`
	border: none;
	border-top: 4px solid #e2e2e2;
`;

export {
	ContainerBase,
	MainCard,
	ContainerPage,
	LayoutPanel,
	FlexHeader,
	FlexHeaderFullWidth,
	FlexHeaderLeft,
	FlexBody,
	FlexFooter,
	StyledTableRow,
	ActionRow,
	ControlRow,
	TwoColField,
	TwoColFieldInline,
	ThreeColField,
	ThreeColFieldInline,
	Row,
	MainPanel,
	Footer,
	FooterBox,
	TitleH1NoBase,
	TitleH1,
	TitleH2NoBase,
	TitleH2,
	TitleH3NoBase,
	TitleH3,
	FlexHeaderChildLeft,
	FieldText,
	TwoColRightGutter,
	TwoColLeftGutter,
	NavigationLink,
	Hr,
};
