import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import AppContext from "../../helpers/AppContext";
import {
	FlexBody,
	FlexHeader,
	MainCard,
	NavigationLink,
	Row,
	ThreeColField,
	TitleH1,
	TitleH3NoBase,
} from "../../helpers/BaseLayoutStyles";
import Loading from "../../components/Loading";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import DayJS from "dayjs";

import { Tab, Tabs } from "baseui/tabs-motion";
import dayjs from "dayjs";
import { timeEnd } from "console";
import { CellComp } from "ag-grid-community";
import TimeModal from "./TimeModal";
import ModalSelectFromList from "../../components/ModalSelectFromList";
import Actions from "../../components/Actions";
import Breadcrumb from "../../components/Breadcrumb";
import CompetitorDetailModal from "../admin/event/CompetitorDetailModal";
import { Button, SHAPE, SIZE } from "baseui/button";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import CompetitorDetail from "../../components/grid/CompetitorDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAngleRight,
	faArrowAltCircleRight,
	faArrowRight,
	faDownload,
	faMobileScreen,
	faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import config from "../../utils/config";

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;
const KEY_ENTER = 13;
const KEY_TAB = 9;

const TimeEditor = forwardRef((props: any, ref: any) => {
	// Cell Editor, based on AG-Grid Numeric example, adapted for Timing use
	const createInitialState = () => {
		let startValue;
		let highlightAllOnFocus = true;

		if (props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE) {
			// if backspace or delete pressed, we clear the cell
			startValue = "";
		} else if (props.charPress) {
			// if a letter was pressed, we start with the letter
			startValue = props.charPress;
			highlightAllOnFocus = false;
		} else {
			// otherwise we start with the current value
			startValue = props.value;
			if (props.keyPress === KEY_F2) {
				highlightAllOnFocus = false;
			}
		}

		return {
			value: startValue,
			highlightAllOnFocus,
		};
	};

	const initialState = createInitialState();

	const [value, setValue] = useState(initialState.value);
	const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(initialState.highlightAllOnFocus);
	const refInput = useRef(null);

	const cancelBeforeStart = props.charPress && "1234567890".indexOf(props.charPress) < 0;

	const isLeftOrRight = (event: any) => {
		return [37, 39].indexOf(event.keyCode) > -1;
	};

	const getCharCodeFromEvent = (event: any) => {
		event = event || window.event;
		return typeof event.which === "undefined" ? event.keyCode : event.which;
	};

	const isCharNumeric = (charStr: string) => {
		return !!/\d/.test(charStr) || /\./.test(charStr) || /:/.test(charStr);
	};

	const isKeyPressedNumeric = (event: any) => {
		const charCode = getCharCodeFromEvent(event);
		const charStr = event.key ? event.key : String.fromCharCode(charCode);
		return isCharNumeric(charStr);
	};

	const deleteOrBackspace = (event: any) => {
		return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.keyCode) > -1;
	};

	const finishedEditingPressed = (event: any) => {
		const charCode = getCharCodeFromEvent(event);
		return charCode === KEY_ENTER || charCode === KEY_TAB;
	};
	const onKeyDown = (event: any) => {
		if (isLeftOrRight(event) || deleteOrBackspace(event)) {
			event.stopPropagation();
			return;
		}

		if (!finishedEditingPressed(event) && !isKeyPressedNumeric(event)) {
			if (event.preventDefault) event.preventDefault();
		}
	};

	useEffect(() => {
		window.addEventListener("keydown", onKeyDown);

		return () => {
			window.removeEventListener("keydown", onKeyDown);
		};
	}, [onKeyDown]);

	useImperativeHandle(ref, () => {
		return {
			afterGuiAttached() {
				// get ref from React component
				const eInput: any = refInput.current;
				if (eInput != null) {
					eInput.focus();
					if (highlightAllOnFocus) {
						eInput.select();

						setHighlightAllOnFocus(false);
					} else {
						// when we started editing, we want the carot at the end, not the start.
						// comes into play in two scenarios: a) when user hits F2 and b)
						// when user hits a printable character, then on IE (and only IE) the carot
						// was placed after the first character, thus 'apply' would end up as 'pplea'
						const length = eInput.value ? eInput.value.length : 0;
						if (length > 0) {
							eInput.setSelectionRange(length, length);
						}
					}
				}
			},

			getValue() {
				// Can do cell formatting here

				return value;
			},

			isCancelBeforeStart() {
				return cancelBeforeStart;
			},

			// will reject the number if it greater than 1,000,000
			// not very practical, but demonstrates the method.
			isCancelAfterEnd() {
				return value > 1000000;
			},
		};
	});

	return (
		<input ref={refInput} value={value} onChange={(event) => setValue(event.target.value)} style={{ width: "100%" }} />
	);
});

const TimingDashboard = (props: any) => {
	//const urlParams = useParams() as any;
	const appContext = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [actionPending, setActionPending] = useState(false);
	const [gridLoading, setGridLoading] = useState(true);
	const [timingData, setTimingData] = useState([] as any);
	const [resultsData, setResultsData] = useState([] as any);
	const [activeKey, setActiveKey] = useState("0");
	const [showExportModal, setShowExportModal] = React.useState(false);

	const [resultsFinalised, setResultsFinalised] = useState(false);

	const [resultId, setResultId] = useState(null as any);
	const [showResultModal, setShowResultModal] = React.useState(false);

	const [showTimeModal, setShowTimeModal] = useState(false);
	const [timeModalRecord, setTimeModalRecord] = useState([] as any);

	let intervalId: any;

	// Grid Configuration
	const [timingGridApi, setTimingGridApi] = useState(null) as any;
	const [timingGridColumnApi, setTimingGridColumnApi] = useState(null) as any;
	const onTimingGridReady = (params: any) => {
		setTimingGridApi(params.api);
		setTimingGridColumnApi(params.columnApi);
		setTimingGridLayout(false);
	};

	let timingColumnDefs: any = [];

	const sortRuns = (a: any, b: any) => {
		if (a.number < b.number) {
			return -1;
		}
		if (a.number > b.number) {
			return 1;
		}
		return 0;
	};

	const setTimingDynamicRunColumnDefs = (api: any, data: any) => {
		if (api != null) {
			timingColumnDefs = [
				{ field: "number", headerName: "", pinned: "left", minWidth: "55", maxWidth: "55" },
				{ field: "name", pinned: "left", minWidth: "150" },
				{ field: "vehicle" },
				{ field: "class", minWidth: "70", maxWidth: "70" },
			];

			// Add our specific runs
			if (data && data.runs) {
				data.runs.sort(sortRuns).forEach((r: any) => {
					timingColumnDefs.push({
						//field: `run${r.number}`,
						//field,
						headerName: r.name,
						valueGetter: (params: any) =>
							params.data[`run${r.number}`] ? params.data[`run${r.number}`].elapsedTimeDisplay : "",
						valueSetter: (params: any) => {
							// Field validation and set values
							const field = `run${params.column.colDef.runNumber}`;
							let cell = params.data[field];
							let timeObj: TimeObject = parseTimeString(params.newValue);

							cell.elapsedTimeDisplay = timeObj.isError ? params.newValue : timeObj.timeString;
							cell.elapsedTime = timeObj.ticks;
							cell.isError = timeObj.isError;
							cell.isSyncing = false;
							cell.validationError = timeObj.validationError;
							if (timeObj.isError) {
								toast.error(timeObj.validationError);
							}

							return !timeObj.isError;
						},
						cellStyle: (params: any) => {
							const cell = params.data[`run${r.number}`];
							let styles: any = {
								backgroundColor: "",
							};
							if (cell.isError == true) {
								styles.backgroundColor = "red";
							} else if (cell.isSyncing == true) {
								styles.backgroundColor = "orange";
							}
							console.log(`Formatting cell ${cell.competitorId}, run ${r.number} as `, styles);

							return styles;
						},
						tooltipValueGetter: (params: any) => {
							//This will show valueFormatted if is present, if no just show the value.
							const cell = params.data[`run${r.number}`];
							return `${cell.validationError}`;
						},
						onCellDoubleClicked: run_onCellDoubleClicked,
						onCellValueChanged: run_onCellValueChanged,
						cellEditor: "timeEditor",
						editable: !resultsFinalised,
						runId: r.id,
						runNumber: r.number,
					});
				});
			}

			api.setColumnDefs(timingColumnDefs);
			api.setRowData(timingData.competitors);
		}
	};

	const setTimingGridLayout = (print: boolean) => {
		if (timingGridApi != null) {
			if (print == true) {
				timingGridApi.setDomLayout("print");
			} else {
				timingGridApi.setDomLayout(null);
			}

			// Resize grid
			const allColumnIds = [] as any;
			if (timingGridColumnApi != null) {
				let cols = timingGridColumnApi.getAllColumns();
				if (cols != null) {
					cols.forEach((column: any) => {
						allColumnIds.push(column.colId);
					});
					timingGridColumnApi.autoSizeColumns(allColumnIds, false);
				}
			}
		}
	};

	// TODO: Move Interface and Time Parsing into a separate library
	interface TimeObject {
		minute: number;
		second: number;
		millisecond: number;
		time: dayjs.Dayjs;
		ticks: number;
		timeString: string;
		validationError: string;
		isError: boolean;
	}

	const parseTimeString = (timeStr: string): TimeObject => {
		let timeObj: TimeObject = {
			minute: 0,
			second: 0,
			millisecond: 0,
			time: dayjs("1970-01-01"),
			ticks: 0,
			timeString: "",
			validationError: "",
			isError: false,
		};

		if (timeStr != "") {
			// Special case from popup - DNS, DNF, WW
			// For these ones skip the validation

			if (timeStr == null || timeStr == "DNS" || timeStr == "DNF" || timeStr == "WW") {
				timeObj.timeString = timeStr || "";
			} else {
				timeStr = timeStr.replaceAll(":", ".");
				let timeParts = timeStr.split(".");

				if (timeParts.length != 3) {
					timeObj.validationError = "Invalid format. Please enter using the format m.ss.sss";
				} else {
					let m = timeParts[0];
					let s = timeParts[1];
					let ms = timeParts[2];
					if (s.length == 1) {
						s = "0" + s;
					}
					if (ms.length == 1) {
						ms = ms + "00";
					} else if (ms.length == 2) {
						ms = ms + "0";
					}

					timeObj.minute = parseInt(m); //parseInt(timeParts[0]);
					timeObj.second = parseInt(s); //parseInt(timeParts[1]);
					timeObj.millisecond = parseInt(ms); //parseInt(timeParts[2]);

					if (timeObj.millisecond > 999) {
						timeObj.validationError = "Invalid format. Milliseconds must be less than 1000";
					}

					if (timeObj.second > 59) {
						timeObj.validationError = "Invalid format. Seconds must be less than 60";
					}

					if (timeObj.minute > 59) {
						timeObj.validationError = "Invalid time. Currently the system only supports times than 60 minutes";
					}
				}
				timeObj.isError = timeObj.validationError != "";

				if (timeObj.isError == false) {
					timeObj.ticks = timeObj.minute * 60000 + timeObj.second * 1000 + timeObj.millisecond;
					timeObj.time = dayjs("1970-01-01")
						.minute(timeObj.minute)
						.second(timeObj.second)
						.millisecond(timeObj.millisecond);
					timeObj.timeString = timeObj.time.format("mm.ss.SSS");
				}
			}
		}

		return timeObj;
	};

	const run_onCellDoubleClicked = (params: any) => {
		if (!resultsFinalised) {
			setTimeModalRecord(params);
			setShowTimeModal(true);
		}
	};

	const closeTimeModal = (params: any) => {
		//setCurrentResultId(null);

		// Add our changed data to the grid
		const field = `run${params.column.colDef.runNumber}`;
		let data = params.data;
		timingGridApi.applyTransaction({ update: [data] });

		setShowTimeModal(false);
		getTimingData();
	};

	const closeResultModals = () => {
		setShowResultModal(false);
		getTimingData();
	};

	const run_onCellValueChanged = async (params: any) => {
		const field = `run${params.column.colDef.runNumber}`;
		let cell = params.data[field];

		// Cell was updated through the Modal popup.
		// Refresh grid with new data
		if (cell.updatedInModal) {
			return;
		}

		if (cell.elapsedTimeDisplay == "DNS" || cell.elapsedTimeDisplay == "DNF" || cell.elapsedTimeDisplay == "WW") {
			// Special exceptions are handled in the popup. Don't do anything here.
			return;
		}
		// Apply style to show we are syncing data
		cell.isError = false;
		cell.validationError = "";
		cell.isSyncing = true;
		timingGridApi.refreshCells({ force: true });

		// Post the request
		let result = await appContext.http.request(
			"POST",
			"/api/timing/dashboard/savetime",
			params.data[field],
			true,
			false
		);

		if (result.success) {
			toast.success(
				`Run ${params.column.colDef.runNumber} time successfully updated for car ${params.data.number} (${params.data.name})`
			);

			// Trigger update to cell state to reflect current database record.
			let data = params.data;
			data[field] = result.data;
			timingGridApi.applyTransaction({ update: [data] });
		} else {
			let validationError = result.data.message || result.message || `${result.res.status} ${result.res.statusText}`;
			toast.error(
				`Error saving run ${params.column.colDef.runNumber} time for car #${params.data.number} (${validationError})`
			);

			// Trigger update to cell state to reflect error.
			let data = params.data;
			data[field] = {
				...data[field],
				isError: true,
				isSyncing: false,
				validationError: validationError,
			};
			timingGridApi.applyTransaction({ update: [data] });
		}
		timingGridApi.refreshCells({ force: true }); // Trigger refresh of cell styles
	};

	let navigate = useNavigate();

	const getTimingData = async () => {
		if (resultId != null) {
			setLoading(true);

			let response = await appContext.http.request(
				"GET",
				`/api/timing/dashboard/timingGrid/${resultId}`,
				null,
				true,
				true
			);

			setLoading(false);
			if (response.success && response.data && response.data.success) {
				setResultsFinalised(response.data.resultsFinalised);
				setTimingData(response.data);
				setTimingGridLayout(false);
			}
		}
	};

	useEffect(() => {
		setResultId(props.resultId);
	}, [appContext, props.resultId]);

	useEffect(() => {
		// Retrieve pageData when resultId is set
		getTimingData();
	}, [resultId]);

	useEffect(() => {
		setTimingDynamicRunColumnDefs(timingGridApi, timingData);
	}, [timingData, timingGridApi]);

	const styles = `
	.ag-header-cell-label .ag-header-cell-text {
		font-size: 12px;
	}

	.ag-header-cell.text-center {
		.ag-header-cell-label {
		  justify-content: center;
		}
	  }


	`;

	return (
		<>
			<div>
				{loading == true && <Loading key="loading" />}
				{loading == false && (
					<div>
						<style>{styles}</style>
						<FlexBody>
							{timingData && resultsData.resultType && (
								<b>
									Timing Dashboard - {timingData.name} {resultsFinalised && <span> (Final Results)</span>}
								</b>
							)}

							<div className="ag-theme-balham" style={{ height: 460, width: "100%" }}>
								<AgGridReact
									rowData={timingData.competitors}
									frameworkComponents={{
										timeEditor: TimeEditor,
									}}
									defaultColDef={{
										flex: 1,
										minWidth: 100,
										editable: false,
										resizable: true,
										sortable: true,
										filter: true,
									}}
									columnDefs={timingColumnDefs}
									onGridReady={onTimingGridReady}
									onFirstDataRendered={() => setTimingGridLayout(false)}
									singleClickEdit={true}
								></AgGridReact>
							</div>
							<Row>
								<div>
									<Button
										kind="secondary"
										size={SIZE.compact}
										shape={SHAPE.pill}
										onClick={() => {
											navigate(`/timing/timing-mobile/${props.resultId}`);
										}}
									>
										<FontAwesomeIcon icon={faMobileScreen} style={{ paddingRight: ".35rem" }} /> Mobile Version
									</Button>{" "}
								</div>
							</Row>
						</FlexBody>
						<TimeModal isOpen={showTimeModal} onClose={closeTimeModal} data={timeModalRecord} />

						{/* <Tab title="Help">
									<FlexBody>
										<h3>Usage Instructions</h3>
										<div>
											<ThreeColField>
												<div>
													<h4>Enter Times</h4>
													<div>
														The Timing tab is used to manually enter or amend times for competitors. It is also used for
														changing Result or Competitor details.
													</div>
													<h5>Operations:</h5>
													<div>
														<ul>
															<li>Edit a competitor - double-click on the competitor name.</li>
															<li>
																Edit result details - click the 'Edit Result' button. This allows for changing the run
																details, adding/removing competitors from the results and other result configuration
																settings.
															</li>
															<li>
																Manually enter/edit an elapsed time - click on a cell and enter the time. You can also
																hit F2 to enable 'Edit' mode on the cell. To save the time either leave the cell or
																press Enter. The cell will show as orange while the data syncs to the server.
															</li>
															<li>
																Manually set complex time data - double-click on a time to open a popup with further
																options for the time. This includes:
																<ul>
																	<li>DNS (did not start)</li>
																	<li>DNF (did not finish)</li>
																	<li>WW (wrong way)</li>
																	<li>
																		Coming soon: edit the elapsed time, view actual Start/End times for the competitor
																	</li>
																	<li>
																		Coming soon: view all times for that run for that competitor (eg: in a Motorkhana
																		with two runs per test, the fastest time for each test will be active and other
																		times inactive)
																	</li>
																</ul>
															</li>
														</ul>
													</div>
												</div>

												<div>
													<h4>View Results</h4>
													<div>
														The Results tab displays the current state of results for the event. This is a similar view
														to that shown in the public-facing version of the results.
													</div>
													<h5>Operations:</h5>
													<div>
														Not yet implemented. Planned functionality includes:
														<ul>
															<li>Ability to print/save a PDF version of the results</li>
														</ul>
													</div>
												</div>
											</ThreeColField>
										</div>
									</FlexBody>
								</Tab> */}
					</div>
				)}
			</div>
		</>
	);
};
export default TimingDashboard;
