export default (props: any) => {
	return (
		<>
			{props.data.name && (
				<>
					<span>
						{props.data.lasttime}

						<br />
						{props.data.lastrun}
					</span>
				</>
			)}
		</>
	);
};
