import React, { useEffect, useState } from "react";
import { Select } from "baseui/select";
import { toast } from "react-toastify";
import { Button, SHAPE, SIZE } from "baseui/button";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faKeyboard, faMobileButton, faMobileScreen, faSave } from "@fortawesome/free-solid-svg-icons";
import { ActionRow, ControlRow, NavigationLink, TwoColField } from "../helpers/BaseLayoutStyles";
import styled from "styled-components";
import { Card, StyledBody } from "baseui/card";

import dayjs from "dayjs";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

const ResultList = styled.div`
	display: block;
	width: 25rem;
	margin-top: 1rem;
`;

const GridListItem = styled.div`
	display: grid;
	grid-template-columns: 15rem 9rem;
	grid-gap: 1rem;
	padding: 0.25rem 0;
`;

/*
	props.data -> contains an Event record

	props.data.results -> list of Results
*/

const EventCard = (props: any) => {
	const [value, setValue] = useState([] as any);

	let navigate = useNavigate();

	useEffect(() => {
		// Initialise the lookup without any value
		setValue(null);
	}, [props]);

	return (
		<>
			<Card overrides={{ Root: { style: { margin: "0.2rem", width: "20rem", height: "14rem" } } }}>
				<StyledBody>
					<GridListItem>
						<div>
							<NavigationLink
								onClick={() => {
									navigate(`/admin/events/edit/${props.data.id}`);
								}}
							>
								<div>
									<strong>{props.data.name}</strong>
								</div>
								<div>Type: {props.data.eventType}</div>
								<div>Venue: {props.data.venue}</div>
								<div>Date: {props.data.eventDate ? dayjs(props.data.eventDate).format("DD MMM YYYY") : ""}</div>
								<br />
								<div>
									<NavigationLink
										onClick={() => {
											navigate(`/admin/events/edit/${props.data.id}`);
										}}
									>
										<FontAwesomeIcon icon={faPenToSquare} style={{ paddingRight: ".35rem" }} />
										<b>Details</b>
									</NavigationLink>
								</div>
							</NavigationLink>
							<div>
								{props.data.results.map((item: any) => {
									return (
										<GridListItem>
											<div>
												<NavigationLink
													onClick={() => {
														navigate(`/timing/timing-mobile/${item.id}`);
													}}
												>
													<FontAwesomeIcon icon={faMobileScreen} style={{ paddingRight: ".35rem" }} />{" "}
													<b>Mobile Timing</b>
												</NavigationLink>
											</div>
										</GridListItem>
									);
								})}
							</div>
						</div>

						<div></div>
					</GridListItem>
				</StyledBody>
			</Card>
		</>
	);
};

export default EventCard;
