import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import AppContext from "../../helpers/AppContext";
import {
	FlexBody,
	FlexHeader,
	FlexHeaderChildLeft,
	FlexHeaderFullWidth,
	FlexHeaderLeft,
	MainCard,
	NavigationLink,
	Row,
	TitleH1,
	TitleH1NoBase,
	TitleH2NoBase,
	TitleH3,
	TitleH3NoBase,
	TwoColField,
} from "../../helpers/BaseLayoutStyles";
import Loading from "../../components/Loading";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import NavLink from "../../components/grid/NavLink";
import dayjs from "dayjs";
import Breadcrumb from "../../components/Breadcrumb";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import CompetitorDetail from "../../components/grid/CompetitorDetail";
import { Button, SHAPE, SIZE } from "baseui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import config from "../../utils/config";
import CarClass from "../../components/grid/CarClass";
import { Select } from "baseui/select";
import LastRunTime from "../../components/grid/LastRunTime";

const Filters = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	padding-bottom: 0.5rem;
`;

const Page = () => {
	const urlParams = useParams() as any;
	const appContext = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [gridLoading, setGridLoading] = useState(true);
	const [actionPending, setActionPending] = useState(false);
	const [showExportModal, setShowExportModal] = React.useState(false);

	const [pageData, setPageData] = useState([] as any);
	const [resultId, setResultId] = useState(null as any);
	const [sortBy, setSortBy] = useState("Overall");
	//const [printLayout, setPrintLayout] = useState(false);
	const [resultsFinalised, setResultsFinalised] = useState(false);

	const [breadcrumbs, setBreadcrumbs] = useState(null) as any;

	// Grid Configuration
	const [resultGridApi, setResultGridApi] = useState(null) as any;
	const [resultGridColumnApi, setResultGridColumnApi] = useState(null) as any;
	const onResultGridReady = (params: any) => {
		setResultGridApi(params.api);
		setResultGridColumnApi(params.columnApi);
		//setGridLayout(printLayout);
		//setDynamicRunColumnDefs(params.api, pageData);
	};

	let columnDefs: any = [];
	let intervalId: any;

	const sortRuns = (a: any, b: any) => {
		if (a.number < b.number) {
			return -1;
		}
		if (a.number > b.number) {
			return 1;
		}
		return 0;
	};

	const setDynamicRunColumnDefs = (api: any, data: any) => {
		if (api != null) {
			columnDefs = [];

			let baseCellStyle: any = {
				lineHeight: "20px",
				fontSize: "12px",
				paddingLeft: "3px",
				paddingRight: "3px",
				paddingTop: "3px",
				paddingBottom: "3px",
			};
			let baseTimeColumnWidth = 75;

			// Layout based on window width
			if (window.innerWidth >= 768) {
				// Full Size layout

				// If the screen is large enough we can make the font and padding larger
				if (window.innerWidth >= 1366) {
					baseCellStyle.fontSize = "14px";
					baseCellStyle.paddingLeft = "5px";
					baseCellStyle.paddingRight = "5px";

					baseTimeColumnWidth = 80;
				}

				columnDefs.push({
					field: "number",
					headerName: "No",
					pinned: "left",
					maxWidth: 50,
					minWidth: 50,
					cellStyle: baseCellStyle,
				});
				columnDefs.push({
					field: "name",
					headerName: "Name",
					pinned: "left",
					maxWidth: 130,
					minWidth: 180,
					cellStyle: baseCellStyle,
				});

				columnDefs.push({
					field: "vehicle",
					headerName: "Vehicle",
					maxWidth: 120,
					minWidth: 120,
					cellStyle: baseCellStyle,
				});
				columnDefs.push({
					field: "class",
					headerName: "Class",
					maxWidth: 55,
					minWidth: 55,
					cellStyle: baseCellStyle,
				});
				// Add our runs
				if (data && data.runs) {
					data.runs.sort(sortRuns).forEach((r: any) => {
						columnDefs.push({
							headerName: r.name,
							maxWidth: baseTimeColumnWidth,
							minWidth: baseTimeColumnWidth,
							valueGetter: (params: any) => {
								return params.data[`run${r.number}`] ? params.data[`run${r.number}`].elapsedTimeDisplay : "";
							},
							cellStyle: (params: any) => {
								const cell = params.data[`run${r.number}`];
								let styles: any = {
									...baseCellStyle,
									backgroundColor: "",
								};
								if (cell) {
									if (sortBy == "Overall" && cell.progressOverall == true) {
										// Elimination - these cars progress to the next round
										styles.backgroundColor = "#efefef";
									} else if (sortBy == "Class" && cell.progressClass == true) {
										// Elimination - these cars progress to the next round
										styles.backgroundColor = "#efefef";
									}
									if (cell.highlightTime == true) {
										styles.fontWeight = "bold";
									}
								}
								return styles;
							},

							editable: false,
							runId: r.id,
							runNumber: r.number,
						});
					});
				}

				if (data.resultType == "FASTEST") {
					columnDefs.push({
						field: "fastest",
						headerName: "Fastest",
						maxWidth: baseTimeColumnWidth,
						minWidth: baseTimeColumnWidth,
						cellStyle: baseCellStyle,
					});
				}
				if (data.resultType == "AGGREGATE") {
					columnDefs.push({
						field: "total",
						headerName: "Total",
						maxWidth: baseTimeColumnWidth,
						minWidth: baseTimeColumnWidth,
						cellStyle: baseCellStyle,
					});
				}

				columnDefs.push({
					field: "positionOverall",
					headerName: "O/A",
					headerClass: "text-center",
					maxWidth: 50,
					minWidth: 50,
					cellStyle: {
						...baseCellStyle,
						textAlign: "center",
					},
				});
				columnDefs.push({
					field: "positionClass",
					headerName: "Cls",
					headerClass: "text-center",
					maxWidth: 50,
					minWidth: 50,
					cellStyle: {
						...baseCellStyle,
						textAlign: "center",
					},
				});

				// if (resultsFinalised == true && data.classifications && data.classifications.length > 0) {
				// 	data.classifications.forEach((r: any) => {
				// 		columnDefs.push({
				// 			field: r.code,
				// 			headerName: r.code,
				// 			minWidth: 65,
				// 			maxWidth: 65,
				// 		});
				// 	});
				// }
			} else {
				// Mobile layout

				columnDefs.push({
					cellRenderer: "carClass",
					headerName: "No",
					minWidth: 55,
					maxWidth: 55,
					autoHeight: true,
					cellRendererParams: {
						showVehicle: true,
					},
					cellStyle: baseCellStyle,
				});

				columnDefs.push({
					cellRenderer: "competitorDetail",
					headerName: "Name",
					minWidth: 170,
					// maxWidth: 400,
					autoHeight: true,
					cellRendererParams: {
						showVehicle: true,
						runs: data.runs.sort(sortRuns),
					},
					cellStyle: (params: any) => {
						const cell = params.data;
						let styles: any = {
							...baseCellStyle,
						};
						if (cell.className) {
							styles.lineHeight = "40px";
						}

						return styles;
					},
				});

				// Add the most useful time column first, to make it easily visible on mobile
				if (data.resultType == "FASTEST") {
					columnDefs.push({
						field: "fastest",
						headerName: "Fastest",
						maxWidth: baseTimeColumnWidth,
						minWidth: baseTimeColumnWidth,
						cellStyle: baseCellStyle,
					});
				}
				if (data.resultType == "AGGREGATE") {
					columnDefs.push({
						field: "total",
						headerName: "Total",
						maxWidth: baseTimeColumnWidth,
						minWidth: baseTimeColumnWidth,
						cellStyle: baseCellStyle,
					});
				}
				if (data.resultType == "ELIMINATION") {
					columnDefs.push({
						field: "lasttime",
						headerName: "Last Time",
						cellRenderer: "lastRunTime",
						maxWidth: baseTimeColumnWidth,
						minWidth: baseTimeColumnWidth,
						cellStyle: baseCellStyle,
					});
				}

				if (sortBy == "Overall") {
					columnDefs.push({
						field: "positionOverall",
						headerName: "O/A",
						headerClass: "text-center",
						maxWidth: 40,
						minWidth: 40,
						cellStyle: {
							...baseCellStyle,
							textAlign: "center",
						},
					});
				} else {
					columnDefs.push({
						field: "positionClass",
						headerName: "Cls",
						headerClass: "text-center",
						maxWidth: 40,
						minWidth: 40,
						cellStyle: {
							...baseCellStyle,
							textAlign: "center",
						},
					});
				}
			}
			// columnDefs.push({ field: "positionOverall", headerName: "O/A", maxWidth: 65, minWidth: 65 });
			// columnDefs.push({ field: "positionClass", headerName: "Cls", maxWidth: 65, minWidth: 65 });

			api.setColumnDefs(columnDefs);
			api.setRowData(pageData.competitors);
		}
	};

	let navigate = useNavigate();

	const getPageData = async () => {
		if (resultId != null) {
			setGridLoading(true);

			let response = await appContext.http.request(
				"GET",
				`/api/public/results/get/${resultId}/${sortBy}`,
				null,
				true,
				false
			);

			if (response.success && response.data && response.data.success) {
				getLastRunTime(response.data);
				setPageData(response.data);
				//setGridLayout(printLayout);
				setResultsFinalised(response.data.resultsFinalised);
			} else {
				if (response.data && response.data.message) {
					toast.error(response.data.message);
				} else {
					toast.error("An unknown error occurred while retrieving page data.");
				}
			}
			setLoading(false);
			setGridLoading(false);
		}
	};

	const getLastRunTime = (data: any) => {
		if (data && data.runs && data.competitors && data.resultType == "ELIMINATION") {
			//const lastRun = data.runs[data.runs.length - 1].number;

			data.competitors.forEach((competitor: any) => {
				for (let i = 10; i > 0; i--) {
					let run = competitor[`run${i}`];
					if (run && run.elapsedTimeDisplay != "" && run.elapsedTimeDisplay != "DNS") {
						competitor.lasttime = run.elapsedTimeDisplay;
						// console.log(
						// 	"Last Run",
						// 	data.runs.find((r: any) => (r.number = i))
						// );
						competitor.lastrun = data.runs.find((r: any) => r.number == i).name;
						break;
					}
				}
			});
		}

		return data;
	};

	const printResult = async () => {
		if (resultId != null) {
			let response = await appContext.http.downloadRequest(
				"GET",
				`/api/public/results/print/${resultId}/${sortBy}`,
				null,
				true,
				true
			);
		}
	};

	useEffect(() => {
		setResultId(urlParams.resultId);
	}, [appContext, urlParams.resultId]);

	useEffect(() => {
		// Retrieve pageData when resultId is set
		getPageData();
	}, [resultId]);

	useEffect(() => {
		setDynamicRunColumnDefs(resultGridApi, pageData);
	}, [pageData, resultGridApi]);

	useEffect(() => {
		if (!resultsFinalised) {
			intervalId = setInterval(() => {
				getPageData();
			}, 60000);
			return () => clearInterval(intervalId);
		} else {
			if (intervalId != null) {
				clearInterval(intervalId);
			}
		}
	}, [pageData, resultsFinalised]);

	useEffect(() => {
		getPageData();
	}, [sortBy]);

	useEffect(() => {
		setBreadcrumbs([
			{ name: "BDA", path: "/" },
			{ name: `Results - ${pageData.name}`, path: `/result/${resultId}` },
		]);
	}, [appContext, pageData]);

	const styles = `
	.ag-header-cell-label .ag-header-cell-text {
		font-size: 12px;
	}

	.ag-header-cell.text-center {
		.ag-header-cell-label {
		  justify-content: center;
		}
	  }

	.ag-header-cell {
		padding: 6px;
		padding-left: 6px !important;
		padding-right: 6px !important;
	}

	.ag-react-container {
		font-size: 12px;
	}
	`;

	return (
		<MainCard>
			{loading == false && (
				<>
					<style>{styles}</style>
					<FlexHeaderFullWidth>
						{resultId ? (
							<>
								{(pageData.eventLogoUrl || pageData.organisationLogoUrl) && (
									<FlexHeader className="responsive resultLogoContainer">
										<div style={{ textAlign: "left" }}>
											{pageData && pageData.eventLogoUrl && (
												<img src={`${config.API_URL}${pageData.eventLogoUrl}`} alt="Logo" className="resultLogo" />
											)}
										</div>
										<div style={{ textAlign: "right" }}>
											{pageData && pageData.organisationLogoUrl && (
												<img
													src={`${config.API_URL}${pageData.organisationLogoUrl}`}
													alt="Logo"
													className="resultLogo"
												/>
											)}
										</div>
									</FlexHeader>
								)}
								<div>
									<TitleH2NoBase className="center">Results - {pageData.name}</TitleH2NoBase>

									<div className="responsive center" style={{ marginTop: "0.5em", marginBottom: "0.5em" }}>
										{pageData.eventDate && <span>{dayjs(pageData.eventDate).format("DD MMMM YYYY")}</span>}
										{pageData.venue && <span> - {pageData.venue}</span>}
									</div>
									<small>
										<Filters>
											<div>
												<Select
													options={[
														{ label: "Overall", id: "Overall" },
														{ label: "Class", id: "Class" },
													]}
													labelKey="label"
													valueKey="id"
													value={[{ id: sortBy }]}
													onChange={(params: any) => {
														setSortBy(params.value[0].id);
													}}
													maxDropdownHeight="200px"
													clearable={false}
													searchable={false}
													size={SIZE.compact}
												/>
											</div>
											<div style={{ textAlign: "right", paddingTop: ".45rem", paddingLeft: ".75rem" }}>
												{resultsFinalised && (
													<NavigationLink
														onClick={() => {
															printResult();
														}}
													>
														<FontAwesomeIcon icon={faFilePdf} style={{ paddingRight: ".25rem" }} /> Download
													</NavigationLink>
												)}
											</div>
										</Filters>
									</small>
								</div>
							</>
						) : (
							<TitleH3>Results</TitleH3>
						)}
					</FlexHeaderFullWidth>
				</>
			)}
			<FlexBody className={pageData.eventLogoUrl || pageData.organisationLogoUrl ? "resultBox" : "resultBoxNoLogo"}>
				{loading == true && <Loading key="loading"></Loading>}
				{loading == false && (
					<>
						<div className="ag-theme-balham" style={{ height: "100%", marginBottom: ".25rem" }}>
							<AgGridReact
								frameworkComponents={{
									competitorDetail: CompetitorDetail,
									carClass: CarClass,
									lastRunTime: LastRunTime,
								}}
								rowData={pageData.competitors}
								defaultColDef={{
									flex: 1,
									minWidth: 100,
									editable: false,
									resizable: true,
									sortable: false,
									filter: false,
								}}
								columnDefs={columnDefs}
								onGridReady={onResultGridReady}
								//onFirstDataRendered={() => setGridLayout(printLayout)}
								domLayout={"autoHeight"}
							></AgGridReact>
						</div>
					</>
				)}
			</FlexBody>
		</MainCard>
	);
};
export default Page;
