import React, { useEffect, useState } from "react";
import AppContext, { AppContextValue } from "./AppContext";
import { toast } from "react-toastify";
import http from "../utils/httpv2";

const AppContextProvider = (props: any) => {
	const [appContextState, setAppContextState] = useState(AppContextValue.state);
	let intervalId: any;

	// Auth logic
	const login = async (userName: string, password: string) => {
		// TODO: use appropriate authentication method based on configuration
		loginBuiltIn(userName, password);
	};

	const loginCookie = async () => {
		const tmpState = { ...appContextState };
		const loginHttp = http(appContextState);

		if (tmpState.auth.authenticated == false && tmpState.auth.cookieLoginAttempted == false) {
			// Because the Auth cookie is httpPost only we can't see if it exists or has been set client-side.
			// So, if we're not logged in and we haven't tried logging in via cookie before, try a cookie login.
			tmpState.auth.isAuthPending = true;
			let result = await loginHttp.request("POST", "/api/lc/auth/returning", {}, false, false);

			tmpState.auth.isAuthPending = false;

			if (result.success) {
				tmpState.auth.authToken = result.data.authToken;
				tmpState.auth.authenticated = true;
				setAppContextState(tmpState);
			}
			tmpState.auth.cookieLoginAttempted = true;
		}
	};

	const loginBuiltIn = async (userName: string, password: string) => {
		const tmpState = { ...appContextState };
		const loginHttp = http(appContextState);

		if (userName === "" || password === "") {
			toast.error("You must enter a username and password.");
		} else {
			tmpState.auth.isAuthPending = true;
			let result = await loginHttp.request(
				"POST",
				"/api/lc/auth/login",
				{
					userName,
					password,
				},
				false,
				false
			);

			tmpState.auth.isAuthPending = false;

			if (result.success) {
				tmpState.auth.authToken = result.data.authToken;
				tmpState.auth.authenticated = true;
				setAppContextState(tmpState);
			} else {
				if (result.data && result.data.message) {
					toast.error(result.data.message);
				} else {
					toast.error("Invalid username or password.");
				}
			}
		}
	};

	const loginJWT = async (jwt: string) => {
		const tmpState = { ...appContextState };
		const loginHttp = http(appContextState);

		if (jwt === "") {
			toast.error("No token has been supplied.");
		} else {
			var regex = new RegExp("^[A-Za-z0-9-_=]+\\.[A-Za-z0-9-_=]+\\.[A-Za-z0-9-_.+/=]*$");
			if (!regex.test(jwt)) {
				toast.error("The token supplied is not in the correct format.");
			} else {
				tmpState.auth.isAuthPending = true;
				let result = await loginHttp.request(
					"POST",
					"/api/auth/token",
					{
						token: jwt,
					},
					false,
					false
				);

				tmpState.auth.isAuthPending = false;

				if (result.success) {
					tmpState.auth.authToken = result.data.authToken;
					tmpState.auth.authenticated = true;
					setAppContextState(tmpState);
				} else {
					if (result.data && result.data.message) {
						toast.error(result.data.message);
					} else {
						toast.error("Validation of the supplied token failed.");
					}
				}
			}
		}
	};

	const logout = async () => {
		const tmpState = { ...appContextState };
		const logoutHttp = http(appContextState);

		//tmpState.auth.userName = "";
		tmpState.auth.authToken = "";
		tmpState.auth.authenticated = false;
		tmpState.userPreferences.isloggedIn = false;
		tmpState.userPreferences.fullName = "";
		tmpState.userPreferences.firstName = "";
		tmpState.userPreferences.lastName = "";

		tmpState.userPreferences.pages = [];
		tmpState.userPreferences.roles = [];
		setAppContextState(tmpState);

		let result = await logoutHttp.request("POST", "/api/lc/auth/logout", {}, false, false);
	};

	const setUserPreferences = async (user: any) => {
		const tmpState = { ...appContextState };
		tmpState.userPreferences.fullName = user.fullName;
		tmpState.userPreferences.firstName = user.firstName;
		tmpState.userPreferences.lastName = user.lastName;

		tmpState.userPreferences.pages = user.userPages;
		tmpState.userPreferences.roles = user.userRoles;

		tmpState.userPreferences.isloggedIn = true;
		setAppContextState(tmpState);
	};

	const keepMeAlive = async () => {
		try {
			const loginHttp = http(appContextState);
			let result = await loginHttp.request("GET", "/api/user/keepmealive", null, true, false);
			if (result.success) {
				console.log(result.data.message);
			} else {
				console.log("KeepSessionAlive call failed");
			}
		} catch {
			// Do nothing. Fail silently
		}
	};

	useEffect(() => {
		if (appContextState && appContextState.auth.authenticated == true) {
			intervalId = setInterval(() => {
				keepMeAlive();
			}, 60000);
			return () => clearInterval(intervalId);
		} else {
			if (intervalId != null) {
				clearInterval(intervalId);
			}
		}
	}, [appContextState]);

	return (
		<AppContext.Provider
			value={{
				state: appContextState,
				mutate: { login, loginJWT, loginCookie, logout, setUserPreferences },
				http: http(appContextState),
			}}
		>
			{props.children}
		</AppContext.Provider>
	);
};
export default AppContextProvider;
