/*
	App.tsx
	Base React Page. This includes wrappers for handling access to the following components:
	* AppContextProvider (for stateful variables across the app, including authentication)
	* BaseWeb themes (BaseProvider)
	* Router
	* Container for Toast notifications
	* Container for our Main page (which is the main page for our SPA React site)
*/

import React from "react";
import styled from "styled-components";
import AppContextProvider from "./helpers/AppContextProvider";
import Main from "./layout/Main";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const engine = new Styletron();

function App() {
	return (
		<>
			<AppContextProvider>
				<StyletronProvider value={engine}>
					<BaseProvider theme={LightTheme}>
						<BrowserRouter>
							<Main></Main>
							<ToastContainer />
						</BrowserRouter>
					</BaseProvider>
				</StyletronProvider>
			</AppContextProvider>
		</>
	);
}

export default App;
