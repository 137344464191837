import React, { useEffect, useState } from "react";
import { Select } from "baseui/select";
import { toast } from "react-toastify";
import { Button, SHAPE, SIZE } from "baseui/button";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPlus, faSave, faUpload } from "@fortawesome/free-solid-svg-icons";
import { ActionRow, ControlRow } from "../helpers/BaseLayoutStyles";
import styled from "styled-components";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

const ButtonContainer = styled.span`
	padding-left: 0.25rem;
	padding-right: 0.25rem;
`;
/*
	Button Types and properties accepted

	* Add
		- addTitle -> the text for the button. This is appended to the normal "+ Add" text.
		- addUrl -> the url to redirect to when clicked. 
		- addAction -> the hook that will be called when clicked.
	
	* Save
		- saveAction -> the hook that will be called when clicked
		- saveActionPending -> the useState() object that determines whether to show/hide the "pending" spinner
	
	* Back
		- backUrl-> the url to redirect to when clicked. 

	* 
*/

const Actions = (props: any) => {
	const [value, setValue] = useState([] as any);

	let navigate = useNavigate();

	useEffect(() => {
		// Initialise the lookup without any value
		setValue(null);
	}, [props]);

	return (
		<>
			<ControlRow>
				<div>
					{props.downloadTemplateAction != null && (
						<ButtonContainer>
							<Button size={SIZE.compact} shape={SHAPE.pill} kind="secondary" onClick={props.downloadTemplateAction}>
								<FontAwesomeIcon icon={faDownload} style={{ paddingRight: ".35rem" }} /> Download Template
							</Button>
						</ButtonContainer>
					)}
					{props.uploadAction != null && (
						<ButtonContainer>
							<Button size={SIZE.compact} shape={SHAPE.pill} kind="secondary" onClick={props.uploadAction}>
								<FontAwesomeIcon icon={faUpload} style={{ paddingRight: ".35rem" }} /> Upload {props.uploadTitle}
							</Button>
						</ButtonContainer>
					)}
					{props.addUrl != null && (
						<ButtonContainer>
							<Button
								size={SIZE.compact}
								shape={SHAPE.pill}
								kind="secondary"
								onClick={() => {
									navigate(props.addUrl);
								}}
							>
								<FontAwesomeIcon icon={faPlus} style={{ paddingRight: ".35rem" }} /> Add {props.addTitle}
							</Button>
						</ButtonContainer>
					)}
					{props.addAction != null && (
						<ButtonContainer>
							<Button size={SIZE.compact} shape={SHAPE.pill} kind="secondary" onClick={props.addAction}>
								<FontAwesomeIcon icon={faPlus} style={{ paddingRight: ".35rem" }} /> Add {props.addTitle}
							</Button>
						</ButtonContainer>
					)}
					{props.editAction != null && (
						<ButtonContainer>
							<Button size={SIZE.compact} shape={SHAPE.pill} kind="secondary" onClick={props.editAction}>
								<FontAwesomeIcon icon={faPenToSquare} style={{ paddingRight: ".35rem" }} /> Edit {props.editTitle}
							</Button>
						</ButtonContainer>
					)}
					{props.saveAction != null && (
						<ButtonContainer>
							<Button
								size={SIZE.compact}
								shape={SHAPE.pill}
								kind="secondary"
								onClick={props.saveAction}
								isLoading={props.saveActionPending}
							>
								<FontAwesomeIcon icon={faSave} />
								&nbsp; Save
							</Button>
						</ButtonContainer>
					)}
				</div>
				<div>
					{props.backUrl != null && (
						<ButtonContainer>
							<Button
								size={SIZE.compact}
								shape={SHAPE.pill}
								kind="secondary"
								onClick={() => {
									navigate(props.backUrl);
								}}
							>
								Back
							</Button>
						</ButtonContainer>
					)}
				</div>
			</ControlRow>
		</>
	);
};

export default Actions;
