import dayjs from "dayjs";
import React from "react";

export default (props: any) => {
	return (
		<span>
			{props.data.number} {props.data.class}
		</span>
	);
};
