import dayjs from "dayjs";
import React from "react";

export default (props: any) => {
	return (
		<span>
			{props.data.eventDate ? dayjs(props.data.eventDate).format("DD/MM/YYYY") : ""}
			<br />
			{props.data.organisation}
		</span>
	);
};
