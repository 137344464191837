import React from "react";
import { Button, SHAPE, SIZE } from "baseui/button";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { NavigationLink } from "../../helpers/BaseLayoutStyles";

export default (props: any) => {
	const cellValue = props.value;

	const modalButton_OnClick = () => {
		props.setObjectId(cellValue);
		props.setShowModal(true);
	};

	return (
		<span>
			<NavigationLink onClick={modalButton_OnClick}>
				<FontAwesomeIcon icon={faPenToSquare} style={{ paddingRight: ".35rem" }} /> {props.text}
			</NavigationLink>
		</span>
	);
};
