import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import AppContext from "../../helpers/AppContext";
import {
	ControlRow,
	FlexBody,
	FlexHeader,
	MainCard,
	ThreeColField,
	TitleH1,
	TitleH3,
} from "../../helpers/BaseLayoutStyles";
import Loading from "../../components/Loading";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import NavLink from "../../components/grid/NavLink";
import ResultNameVenue from "../../components/grid/ResultNameVenue";
import ResultDateOrganisation from "../../components/grid/ResultDateOrganisation";
import { FormControl } from "baseui/form-control";
import dayjs from "dayjs";
import Breadcrumb from "../../components/Breadcrumb";
import { Select } from "baseui/select";
import { Button, KIND, SIZE } from "baseui/button";
import { DatePicker } from "baseui/datepicker";

const Page = () => {
	const appContext = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [actionPending, setActionPending] = useState(false);

	const [recentResults, setRecentResults] = useState(null as any);
	const [breadcrumbs, setBreadcrumbs] = useState(null) as any;
	const [filters, setFilters] = useState(null as any);

	// Grid Configuration
	const [gridApi, setGridApi] = useState(null) as any;
	const [gridColumnApi, setGridColumnApi] = useState(null) as any;
	const onGridReady = (params: any) => {
		setGridApi(params.api);
		setGridColumnApi(params.columnApi);
		setColumnDefs(params.api);
		setGridLayout(false);
	};

	let columnDefs: any = [];

	const setColumnDefs = (api: any) => {
		if (api != null) {
			columnDefs = [];

			let baseCellStyle: any = {
				// lineHeight: "20px",
				// fontSize: "12px",
				// paddingLeft: "3px",
				// paddingRight: "3px",
				// paddingTop: "3px",
				// paddingBottom: "3px",
			};

			// If the screen is large enough we can make the font and padding larger
			if (window.innerWidth < 1366) {
				baseCellStyle.fontSize = "12px";
				baseCellStyle.paddingLeft = "3px";
				baseCellStyle.paddingRight = "3px";
			}

			columnDefs.push({
				cellRenderer: "resultDateOrganisation",
				headerName: "Date",
				minWidth: 110,
				maxWidth: 110,
				autoHeight: true,
				cellStyle: baseCellStyle,
			});

			columnDefs.push({
				cellRenderer: "resultNameVenue",
				headerName: "Event",
				autoHeight: true,
				cellStyle: baseCellStyle,
			});

			api.setColumnDefs(columnDefs);
			//api.setRowData(pageData.competitors);
		}
	};

	const setGridLayout = (print: boolean) => {
		if (gridApi != null) {
			if (print == true) {
				gridApi.setDomLayout("print");
			} else {
				gridApi.setDomLayout(null);
			}

			// This may be a better way of doing grid sizing
			// Set min and max column widths on columns we care about, then everything can resize dynamically up to those maximums
			gridApi.sizeColumnsToFit();
		}
	};

	let navigate = useNavigate();
	let reportName = "ResultSearch";

	const setFilterProperty = async (key: string, value: any, remove: boolean = false) => {
		let staleData = Object.assign({}, filters);

		if (Array.isArray(staleData[key])) {
			// Push and Pop items from the array
			if (!remove) {
				staleData[key].push(value);
			} else {
				// This assumes that both the value provided and the objects in the array have id fields
				// Find the object matching our id in the array.
				let re = staleData[key].find((e: any) => e.id == value.id);
				if (re != null) {
					// Object found, now remove it.
					const index = staleData[key].indexOf(re, 0);
					if (index > -1) {
						staleData[key].splice(index, 1);
					}
				}
			}
		} else {
			// Just update the value
			if (Array.isArray(value)) {
				value = value[0];
			}
			staleData[key] = value;
		}
		setFilters(staleData);
		getPageData(staleData);
	};

	const getFilters = async () => {
		setLoading(true);
		let response = await appContext.http.request("GET", `/api/reports/filters/${reportName}`, null, true, true);

		if (response.success) {
			// Set State objects
			setFilters(response.data);
			getPageData(response.data);
		}
		setLoading(false);
	};

	const getPageData = async (criteria: any) => {
		setLoading(true);

		criteria = criteria || filters;

		let request = {
			reportName: reportName,
			filters: criteria,
		};

		let response = await appContext.http.request("POST", `/api/reports/get`, request, true, true);

		if (response.success && response.data && response.data.success) {
			setRecentResults(response.data);
			setGridLayout(false);
		} else {
			if (response.data && response.data.message) {
				toast.error(response.data.message);
			} else {
				toast.error("An unknown error occurred while retrieving page data.");
			}
		}
		setLoading(false);
	};

	useEffect(() => {
		getFilters();
	}, [appContext]);

	useEffect(() => {
		setBreadcrumbs([
			{ name: "BDA", path: "/" },
			{ name: "Results Search", path: "/" },
		]);
	}, [appContext]);

	const styles = `
	@media only screen and (max-width: 767px) {
		.ag-header-cell-label .ag-header-cell-text {
			font-size: 12px;
		}

		.ag-header-cell.text-center {
			.ag-header-cell-label {
			justify-content: center;
			}
		}

		.ag-header-cell {
			padding: 6px;
			padding-left: 6px !important;
			padding-right: 6px !important;
		}

		.ag-react-container {
			font-size: 12px;
		}
	}
	`;
	return (
		<MainCard>
			<style>{styles}</style>
			<FlexHeader>
				<div className="responsive" />
				<TitleH1>Result Search</TitleH1>
			</FlexHeader>
			<FlexBody className="flexBody150">
				{filters != null && (
					<>
						<ThreeColField>
							<div style={{ marginBottom: ".25rem" }}>
								<Select
									value={filters.organisation}
									onChange={(params) => setFilterProperty("organisation", params.value)}
									options={filters.organisationList.items}
									placeholder=""
								/>
							</div>
						</ThreeColField>
						{/* <ControlRow>
							<Button
								onClick={() => {
									getPageData();
								}}
								kind={KIND.secondary}
								size={SIZE.compact}
							>
								Run Report
							</Button>
						</ControlRow> */}
					</>
				)}
				{loading == true && <Loading key="loading"></Loading>}

				{loading == false && recentResults && recentResults.data && (
					<div className="ag-theme-balham" style={{ height: "100%", width: "100%" }}>
						<AgGridReact
							rowData={recentResults.data}
							frameworkComponents={{
								navLinkRenderer: NavLink,
								resultNameVenue: ResultNameVenue,
								resultDateOrganisation: ResultDateOrganisation,
							}}
							defaultColDef={{
								flex: 1,
								minWidth: 100,
								editable: false,
								resizable: false,
								sortable: true,
								filter: true,
							}}
							onGridReady={onGridReady}
							onRowClicked={(e) => navigate(`/result/${e.data.id}`)}
							onFirstDataRendered={() => setGridLayout(false)}
							pagination={true}
							paginationPageSize={10}
							columnDefs={columnDefs}
						>
							{/* <AgGridColumn
								field="eventDate"
								headerName="Date"
								maxWidth={100}
								// cellRenderer={(data) => {
								// 	return data.value ? dayjs(data.value).format("DD MMM YYYY") : "";
								// }}
								cellRenderer="resultDateOrganisation"
							/>
							<AgGridColumn headerName="Event" cellRenderer="resultNameVenue" /> */}
							{/* <AgGridColumn
								field="id"
								cellRenderer="navLinkRenderer"
								headerName=""
								maxWidth={50}
								cellRendererParams={{ text: "View", url: "/result" }}
							/> */}
						</AgGridReact>
					</div>
				)}
			</FlexBody>
		</MainCard>
	);
};
export default Page;
