/*
	Nav.tsx
	Currently this is a hamburger. 
	We also want a version for a dashboard-style layout with a list of items pre-expanded on the side.
*/

import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import AppContext from "../helpers/AppContext";
import { ControlRow } from "../helpers/BaseLayoutStyles";
import { Drawer, ANCHOR } from "baseui/drawer";
import { Button, SHAPE, SIZE } from "baseui/button";
import { Menu } from "baseui/icon";
import { Card, StyledBody } from "baseui/card";
import { Avatar } from "baseui/avatar";
import { useNavigate } from "react-router-dom";
import config from "../utils/config";
import { Modal, ModalBody, ModalHeader } from "baseui/modal";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import NavContent from "./NavContent";

const NavContainer = styled.div`
	flex: 0 1 auto;
	padding: 0.25rem 0;
	background-color: #fff;
`;

const NavFlex = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Constrain = styled.div`
	margin: 0 1rem;
	margin-left: 0;
	@media screen and (min-width: 1031px) {
		margin: 0 1rem;
	}
`;

const Hamburger = styled.div`
	@media screen and (min-width: 1031px) {
		display: none;
	}
`;

const NavLeft = styled.div`
	display: flex;
	align-items: center;
`;

const NavCentre = styled.div`
	display: flex;
	align-items: center;
`;

const NavLayout = (props: any) => {
	const [signingIn, setSigningIn] = useState(false);
	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const appContext = useContext(AppContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (appContext.state.auth.authenticated === true) {
			setSigningIn(false);
		}
	}, [appContext]);

	return (
		<>
			<NavContainer key="navigation">
				{/* Container for top menu bar. */}
				<Constrain>
					<NavFlex>
						<NavLeft>
							<Hamburger>
								<Button
									shape={SHAPE.square}
									onClick={() => {
										props.setMenuIsOpen(true);
									}}
									kind="tertiary"
									size={SIZE.compact}
								>
									<Menu />
								</Button>
							</Hamburger>

							<a
								onClick={() => {
									navigate(`/`);
									props.setMenuIsOpen(false);
								}}
							>
								<img src={config.BRANDING_LOGO_SRC} alt={config.BRANDING} style={{ height: "2rem" }} />
							</a>
						</NavLeft>
						{config.ENVIRONMENT != "Production" && (
							<NavCentre>
								<h3>
									<span>{config.ENVIRONMENT}</span>
								</h3>
							</NavCentre>
						)}
					</NavFlex>
				</Constrain>
			</NavContainer>

			<Drawer
				isOpen={props.menuIsOpen}
				autoFocus
				onClose={() => props.setMenuIsOpen(false)}
				anchor={ANCHOR.left}
				size={SIZE.default}
			>
				<NavContent loading={props.loading} user={props.user} setMenuIsOpen={props.setMenuIsOpen}></NavContent>
			</Drawer>

			<Modal onClose={() => setSigningIn(false)} isOpen={signingIn}>
				<ModalHeader>Log In</ModalHeader>
				<ModalBody>
					<div>
						<FormControl label={() => "Username *"}>
							<Input onChange={(e) => setUserName(e.currentTarget.value)} placeholder="Enter your username" />
						</FormControl>
						<FormControl label={() => "Password *"}>
							<Input
								onChange={(e) => setPassword(e.currentTarget.value)}
								type="password"
								placeholder="Enter your password"
							/>
						</FormControl>

						<ControlRow>
							<Button
								kind="secondary"
								isLoading={appContext.state.auth.isAuthPending}
								onClick={() => {
									appContext.mutate.login(userName, password);
								}}
								size={SIZE.compact}
								shape={SHAPE.pill}
							>
								Log In
							</Button>

							<Button
								kind="secondary"
								onClick={() => {
									setSigningIn(false);
								}}
								size={SIZE.compact}
								shape={SHAPE.pill}
							>
								Cancel
							</Button>
						</ControlRow>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};
export default NavLayout;
