import React, { useEffect, useState } from "react";
import { Select } from "baseui/select";
import { toast } from "react-toastify";
import { Button, SHAPE, SIZE } from "baseui/button";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { ActionRow, ControlRow } from "../helpers/BaseLayoutStyles";
import styled from "styled-components";

const BreadcrumbContainer = styled.div`
	text-align: left !important;
	font-size: 0.7em;
	padding-bottom: 10px;
`;

const BreadcrumbLink = styled.a`
	cursor: pointer;
`;

const Breadcrumb = (props: any) => {
	const [value, setValue] = useState([] as any);

	let navigate = useNavigate();

	useEffect(() => {
		// Initialise the lookup without any value
		setValue(null);
	}, [props]);

	return (
		<>
			<BreadcrumbContainer className="responsive">
				{props.breadcrumbs &&
					props.breadcrumbs.map((item: any, index: any) => {
						return (
							<>
								<span> &gt; </span>
								<BreadcrumbLink
									onClick={() => {
										if (item.path) {
											navigate(item.path);
										}
									}}
								>
									{item.name}
								</BreadcrumbLink>
							</>
						);
					})}
			</BreadcrumbContainer>
		</>
	);
};

export default Breadcrumb;
