import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import AppContext from "../../helpers/AppContext";
import { ControlRow, FlexBody, Hr, MainCard, Row, TwoColField } from "../../helpers/BaseLayoutStyles";
import Loading from "../../components/Loading";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import dayjs from "dayjs";
import { timeEnd } from "console";
import { CellComp } from "ag-grid-community";
import TimeModal from "./TimeModal";
import Breadcrumb from "../../components/Breadcrumb";
import { Button, SHAPE, SIZE } from "baseui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAngleRight,
	faArrowAltCircleRight,
	faArrowLeft,
	faArrowRight,
	faDownload,
	faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import config from "../../utils/config";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";

const SearchLine = styled.div`
	display: grid;
	grid-template-columns: 2fr 3fr;
	grid-gap: 1rem;
`;
const CompetitorDetailLine = styled.div`
	display: grid;
	grid-template-columns: 1fr 3fr;
	grid-gap: 1rem;
`;
const CarNumber = styled.div`
	height: 100%;
	width: 100%;
	font-size: 4em;
	font-align: center;
`;

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;
const KEY_ENTER = 13;
const KEY_TAB = 9;

const TimeEditor = forwardRef((props: any, ref: any) => {
	// Cell Editor, based on AG-Grid Numeric example, adapted for Timing use
	const createInitialState = () => {
		let startValue;
		let highlightAllOnFocus = false;

		if (props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE) {
			// if backspace or delete pressed, we clear the cell
			startValue = "";
		} else if (props.charPress) {
			// if a letter was pressed, we start with the letter
			startValue = props.charPress;
			highlightAllOnFocus = false;
		} else {
			// otherwise we start with the current value
			startValue = props.value;
			if (props.keyPress === KEY_F2) {
				highlightAllOnFocus = false;
			}
		}

		return {
			value: startValue,
			highlightAllOnFocus,
		};
	};

	const initialState = createInitialState();

	const [value, setValue] = useState(initialState.value);
	const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(initialState.highlightAllOnFocus);
	const refInput = useRef(null);

	const cancelBeforeStart = props.charPress && "1234567890".indexOf(props.charPress) < 0;

	const isLeftOrRight = (event: any) => {
		return [37, 39].indexOf(event.keyCode) > -1;
	};

	const getCharCodeFromEvent = (event: any) => {
		event = event || window.event;
		return typeof event.which === "undefined" ? event.keyCode : event.which;
	};

	const isCharNumeric = (charStr: string) => {
		return !!/\d/.test(charStr) || /\./.test(charStr) || /:/.test(charStr);
	};

	const isKeyPressedNumeric = (event: any) => {
		const charCode = getCharCodeFromEvent(event);
		const charStr = event.key ? event.key : String.fromCharCode(charCode);
		return isCharNumeric(charStr);
	};

	const deleteOrBackspace = (event: any) => {
		return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.keyCode) > -1;
	};

	const finishedEditingPressed = (event: any) => {
		const charCode = getCharCodeFromEvent(event);
		return charCode === KEY_ENTER || charCode === KEY_TAB;
	};
	const onKeyDown = (event: any) => {
		if (isLeftOrRight(event) || deleteOrBackspace(event)) {
			event.stopPropagation();
			return;
		}

		if (!finishedEditingPressed(event) && !isKeyPressedNumeric(event)) {
			if (event.preventDefault) event.preventDefault();
		}
	};

	useEffect(() => {
		window.addEventListener("keydown", onKeyDown);

		return () => {
			window.removeEventListener("keydown", onKeyDown);
		};
	}, [onKeyDown]);

	useImperativeHandle(ref, () => {
		return {
			afterGuiAttached() {
				// get ref from React component
				const eInput: any = refInput.current;
				if (eInput != null) {
					eInput.focus();
					if (highlightAllOnFocus) {
						eInput.select();

						setHighlightAllOnFocus(false);
					} else {
						// when we started editing, we want the carot at the end, not the start.
						// comes into play in two scenarios: a) when user hits F2 and b)
						// when user hits a printable character, then on IE (and only IE) the carot
						// was placed after the first character, thus 'apply' would end up as 'pplea'
						const length = eInput.value ? eInput.value.length : 0;
						if (length > 0) {
							eInput.setSelectionRange(length, length);
						}
					}
				}
			},

			getValue() {
				// Can do cell formatting here

				return value;
			},

			isCancelBeforeStart() {
				return cancelBeforeStart;
			},

			// will reject the number if it greater than 1,000,000
			// not very practical, but demonstrates the method.
			isCancelAfterEnd() {
				return value > 1000000;
			},
		};
	});

	return (
		<input
			ref={refInput}
			inputMode="decimal"
			value={value}
			onChange={(event) => setValue(event.target.value)}
			style={{ width: "100%", fontSize: "20px" }}
		/>
	);
});

const TimingMobile = () => {
	const urlParams = useParams() as any;
	const appContext = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [actionPending, setActionPending] = useState(false);
	const [gridLoading, setGridLoading] = useState(false);
	const [timingData, setTimingData] = useState([] as any);
	const [currentCompetitor, setCurrentCompetitor] = useState(null as any);
	const [currentCompetitorNo, setCurrentCompetitorNo] = useState("" as any);
	const [currentTimes, setCurrentTimes] = useState([] as any);
	const [resultsData, setResultsData] = useState([] as any);
	const [activeKey, setActiveKey] = useState("0");
	const [showExportModal, setShowExportModal] = React.useState(false);

	const [resultsFinalised, setResultsFinalised] = useState(false);

	const [resultId, setResultId] = useState(null as any);
	const [showResultModal, setShowResultModal] = React.useState(false);

	const [showTimeModal, setShowTimeModal] = useState(false);
	const [timeModalRecord, setTimeModalRecord] = useState([] as any);

	let intervalId: any;

	// Grid Configuration
	const [timingGridApi, setTimingGridApi] = useState(null) as any;
	const [timingGridColumnApi, setTimingGridColumnApi] = useState(null) as any;
	const onTimingGridReady = (params: any) => {
		setTimingGridApi(params.api);
		setTimingGridColumnApi(params.columnApi);
		//setTimingGridLayout(false);
	};

	let timingColumnDefs: any = [];

	const sortRuns = (a: any, b: any) => {
		if (a.number < b.number) {
			return -1;
		}
		if (a.number > b.number) {
			return 1;
		}
		return 0;
	};

	const setTimingDynamicRunColumnDefs = (api: any, data: any) => {
		if (api != null) {
			timingColumnDefs = [
				{ field: "run", headerName: "Run", width: 150, flex: 1, cellStyle: { paddingTop: "5px" } },
				{
					headerName: "Elapsed Time",
					flex: 2,
					valueGetter: (params: any) => (params.data.elapsedTimeDisplay ? params.data.elapsedTimeDisplay : ""),
					valueSetter: (params: any) => {
						// Field validation and set values
						//const field = `run${params.column.colDef.runNumber}`;
						let cell = params.data; //[field];
						let timeObj: TimeObject = parseTimeString(params.newValue);

						cell.elapsedTimeDisplay = timeObj.isError ? params.newValue : timeObj.timeString;
						cell.elapsedTime = timeObj.ticks;
						cell.isError = timeObj.isError;
						cell.isSyncing = false;
						cell.validationError = timeObj.validationError;
						if (timeObj.isError) {
							toast.error(timeObj.validationError);
						}

						return !timeObj.isError;
					},
					cellStyle: (params: any) => {
						//const field = `run${params.column.colDef.runNumber}`;
						const cell = params.data; //[field];
						let styles: any = {
							backgroundColor: "",
							fontSize: "24px",
							paddingTop: "5px",
						};
						if (cell) {
							if (cell.isError == true) {
								styles.backgroundColor = "red";
							} else if (cell.isSyncing == true) {
								styles.backgroundColor = "orange";
							}
						}

						return styles;
					},
					tooltipValueGetter: (params: any) => {
						//This will show valueFormatted if is present, if no just show the value.
						//const field = `run${params.column.colDef.runNumber}`;
						const cell = params.data; //[field];
						return cell ? `${cell.validationError}` : "";
					},
					onCellDoubleClicked: run_onCellDoubleClicked,
					onCellValueChanged: run_onCellValueChanged,
					cellEditor: "timeEditor",
					editable: !resultsFinalised,
					runId: data.runId,
					runNumber: data.runNumber,
				},
			];

			api.setColumnDefs(timingColumnDefs);
		}
	};

	// TODO: Move Interface and Time Parsing into a separate library
	interface TimeObject {
		minute: number;
		second: number;
		millisecond: number;
		time: dayjs.Dayjs;
		ticks: number;
		timeString: string;
		validationError: string;
		isError: boolean;
	}

	const parseTimeString = (timeStr: string): TimeObject => {
		let timeObj: TimeObject = {
			minute: 0,
			second: 0,
			millisecond: 0,
			time: dayjs("1970-01-01"),
			ticks: 0,
			timeString: "",
			validationError: "",
			isError: false,
		};

		if (timeStr != "") {
			// Special case from popup - DNS, DNF, WW
			// For these ones skip the validation

			if (timeStr == null || timeStr == "DNS" || timeStr == "DNF" || timeStr == "WW") {
				timeObj.timeString = timeStr || "";
			} else {
				timeStr = timeStr.replaceAll(":", ".");
				let timeParts = timeStr.split(".");

				if (timeParts.length != 3) {
					timeObj.validationError = "Invalid format. Please enter using the format m.ss.sss";
				} else {
					let m = timeParts[0];
					let s = timeParts[1];
					let ms = timeParts[2];
					if (s.length == 1) {
						s = "0" + s;
					}
					if (ms.length == 1) {
						ms = ms + "00";
					} else if (ms.length == 2) {
						ms = ms + "0";
					}

					timeObj.minute = parseInt(m); //parseInt(timeParts[0]);
					timeObj.second = parseInt(s); //parseInt(timeParts[1]);
					timeObj.millisecond = parseInt(ms); //parseInt(timeParts[2]);

					if (timeObj.millisecond > 999) {
						timeObj.validationError = "Invalid format. Milliseconds must be less than 1000";
					}

					if (timeObj.second > 59) {
						timeObj.validationError = "Invalid format. Seconds must be less than 60";
					}

					if (timeObj.minute > 59) {
						timeObj.validationError = "Invalid time. Currently the system only supports times than 60 minutes";
					}
				}
				timeObj.isError = timeObj.validationError != "";

				if (timeObj.isError == false) {
					timeObj.ticks = timeObj.minute * 60000 + timeObj.second * 1000 + timeObj.millisecond;
					timeObj.time = dayjs("1970-01-01")
						.minute(timeObj.minute)
						.second(timeObj.second)
						.millisecond(timeObj.millisecond);
					timeObj.timeString = timeObj.time.format("mm.ss.SSS");
				}
			}
		}

		return timeObj;
	};

	const run_onCellDoubleClicked = (params: any) => {
		if (!resultsFinalised) {
			setTimeModalRecord(params);
			setShowTimeModal(true);
		}
	};

	const closeTimeModal = (params: any) => {
		//setCurrentResultId(null);

		// Add our changed data to the grid
		//const field = `run${params.column.colDef.runNumber}`;
		let data = params.data;
		timingGridApi.applyTransaction({ update: [data] });

		setShowTimeModal(false);
		getTimingData();
	};

	const run_onCellValueChanged = async (params: any) => {
		let cell = params.data;

		// Cell was updated through the Modal popup.
		// Refresh grid with new data
		if (cell.updatedInModal) {
			return;
		}

		if (cell.elapsedTimeDisplay == "DNS" || cell.elapsedTimeDisplay == "DNF" || cell.elapsedTimeDisplay == "WW") {
			// Special exceptions are handled in the popup. Don't do anything here.
			return;
		}
		// Apply style to show we are syncing data
		cell.isError = false;
		cell.validationError = "";
		cell.isSyncing = true;
		timingGridApi.refreshCells({ force: true });

		// Post the request
		let result = await appContext.http.request(
			"POST",
			"/api/timing/dashboard/savetime",
			params.data, //[field],
			true,
			false
		);

		if (result.success) {
			toast.success(`${result.data.run} time successfully updated for car #${currentCompetitorNo}`);

			// Trigger update to cell state to reflect current database record.
			let data = result.data;
			timingGridApi.applyTransaction({ update: [data] });
		} else {
			let validationError = result.data.message || result.message || `${result.res.status} ${result.res.statusText}`;
			toast.error(`Error saving time for car #${currentCompetitorNo}`);

			// Trigger update to cell state to reflect error.
			let data = params.data;
			data = {
				...data,
				isError: true,
				isSyncing: false,
				validationError: validationError,
			};

			timingGridApi.applyTransaction({ update: [data] });
		}
		timingGridApi.refreshCells({ force: true }); // Trigger refresh of cell styles
	};

	const searchCompetitor = async (overrideData: any = null) => {
		let sourceData = overrideData || timingData;

		let competitor = sourceData.competitors.find((c: any) => c.number == currentCompetitorNo);

		if (competitor) {
			let times = [] as any;
			for (let i = 1; i <= sourceData.runs.length; i++) {
				times.push(competitor[`run${i}`]);
			}
			setCurrentCompetitor(competitor);
			setCurrentTimes(times);
			setTimingDynamicRunColumnDefs(timingGridApi, times);
		} else {
			setCurrentCompetitor(null);
			setCurrentTimes([]);
			toast.error(`Car #${currentCompetitorNo} not found`);
		}
	};

	let navigate = useNavigate();

	const getTimingData = async () => {
		if (resultId != null) {
			setGridLoading(true);

			let response = await appContext.http.request(
				"GET",
				`/api/timing/dashboard/timingGrid/${resultId}`,
				null,
				true,
				true
			);
			setLoading(false);
			setGridLoading(false);
			if (response.success && response.data && response.data.success) {
				setResultsFinalised(response.data.resultsFinalised);
				setTimingData(response.data);
				//setTimingGridLayout(false);

				if (currentCompetitorNo != "") {
					searchCompetitor(response.data);
				}
			}
		}
	};

	useEffect(() => {
		setResultId(urlParams.resultId);
	}, [appContext, urlParams.resultId]);

	useEffect(() => {
		// Retrieve pageData when resultId is set
		getTimingData();
	}, [resultId]);

	useEffect(() => {
		// setTimingDynamicRunColumnDefs(timingGridApi, timingData);
	}, [timingData, timingGridApi]);

	const styles = `
.ag-react-container {
	height: 40px;
}
.ag-cell-inline-editing {
	height: 40px !important;
}
`;

	return (
		<>
			<style>{styles}</style>
			<MainCard>
				{loading == true && <Loading key="loading" />}
				{loading == false && (
					<div>
						<Breadcrumb breadcrumbs={timingData.breadcrumbs} />
						<FlexBody>
							{timingData && (
								<b>
									Mobile Timing Entry
									<br /> {timingData.name}
								</b>
							)}

							<div>
								<FormControl label={() => "Enter Car Number"}>
									<SearchLine>
										<div>
											<Input
												value={currentCompetitorNo}
												disabled={false}
												inputMode="decimal"
												autoComplete="off"
												onChange={(e) => {
													setCurrentCompetitorNo(e.currentTarget.value);
												}}
												onFocus={(e) => {
													e.currentTarget.select();
												}}
											/>
										</div>
										<div>
											<Button
												size={SIZE.compact}
												shape={SHAPE.pill}
												onClick={() => {
													searchCompetitor();
												}}
											>
												Search
											</Button>
										</div>
									</SearchLine>
								</FormControl>
							</div>

							<Hr />
							{currentCompetitor && (
								<>
									<CompetitorDetailLine>
										<div>
											<FormControl label={() => "Car Number"}>
												<CarNumber>{currentCompetitor.number}</CarNumber>
											</FormControl>
										</div>
										<div>
											<FormControl label={() => "Details"}>
												<div>
													{currentCompetitor.name}
													<br />
													<br />
													{currentCompetitor.vehicle}
													<br />
													Class {currentCompetitor.class}
												</div>
											</FormControl>
										</div>
									</CompetitorDetailLine>
									<br />
								</>
							)}

							<div className="ag-theme-balham" style={{ height: 300, width: "100%" }}>
								<AgGridReact
									rowData={currentTimes}
									frameworkComponents={{
										timeEditor: TimeEditor,
									}}
									defaultColDef={{
										editable: false,
										resizable: true,
										sortable: false,
										filter: false,
									}}
									columnDefs={timingColumnDefs}
									onGridReady={onTimingGridReady}
									//onFirstDataRendered={() => setTimingGridLayout(false)}
									singleClickEdit={true}
									getRowNodeId={(data) => {
										return data.runId;
									}}
									rowHeight={40}
								></AgGridReact>
							</div>

							<ControlRow>
								<Button
									kind="secondary"
									size={SIZE.compact}
									shape={SHAPE.pill}
									onClick={() => {
										getTimingData();
									}}
								>
									<FontAwesomeIcon icon={faRefresh} style={{ paddingRight: ".35rem" }} />
									Refresh
								</Button>{" "}
								<Button
									kind="secondary"
									size={SIZE.compact}
									shape={SHAPE.pill}
									onClick={() => {
										navigate(-1);
									}}
								>
									<FontAwesomeIcon icon={faArrowLeft} style={{ paddingRight: ".35rem" }} />
									Back
								</Button>{" "}
							</ControlRow>
						</FlexBody>
						<TimeModal isOpen={showTimeModal} onClose={closeTimeModal} data={timeModalRecord} />
					</div>
				)}
			</MainCard>
		</>
	);
};
export default TimingMobile;
