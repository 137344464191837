import React, { useEffect, useMemo, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE } from "baseui/modal";
import { Select } from "baseui/select";
import { toast } from "react-toastify";
import { SHAPE } from "baseui/button";
import { FormControl } from "baseui/form-control";

const RoleModal = (props: any) => {
	function close() {
		props.setIsOpen(false);
	}

	const [value, setValue] = useState([] as any);

	useEffect(() => {
		// Initialise the lookup without any value
		setValue(null);
	}, [props]);

	return (
		<>
			<Modal onClose={close} isOpen={props.isOpen}>
				<ModalHeader>{props.title}</ModalHeader>
				<ModalBody>
					<FormControl label={() => "Role"}>
						<Select
							value={value}
							// onChange={setValue}
							onChange={(params) => setValue(params.value)}
							options={props.options}
							// size={SIZE.default}
						/>
					</FormControl>
					<FormControl label={() => "Scope"}>
						{props.authScopeId != null ? <div>{props.authScopeName}</div> : <div>Global</div>}
					</FormControl>
				</ModalBody>
				<ModalFooter>
					<ModalButton shape={SHAPE.pill} kind="secondary" onClick={close}>
						Cancel
					</ModalButton>
					{/* <ModalButton onClick={close}>Add</ModalButton> */}
					<ModalButton
						shape={SHAPE.pill}
						kind="secondary"
						onClick={() => {
							if (value == null || value.length == 0) {
								toast.error("Please select a value to add.");
								return;
							}

							if (
								props.currentValues != null &&
								props.currentValues.filter((e: any) => e.id === value[0].id).length > 0
							) {
								toast.error("This item has already been added.");
								return;
							}

							// Return an id/name object, rather than the id/label object used in the lookup
							let retVal = {
								id: value[0].id,
								name: value[0].label,
								authScopeId: null,
								authScopeName: "",
							};
							if (props.authScopeId != null) {
								retVal.authScopeId = props.authScopeId;
								retVal.authScopeName = props.authScopeName;
							} else {
								retVal.authScopeName = "Global";
							}

							props.onAdd(retVal);
							close();
						}}
					>
						Add
					</ModalButton>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default RoleModal;
